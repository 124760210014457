import builder from 'xmlbuilder';

import {
  nextId
} from './util';

const xmlns = 'https://www.omg.org/spec/DMN/20191111/MODEL/';

const xmlnsDmndi = 'https://www.omg.org/spec/DMN/20191111/DMNDI/';

const xmlnsDc = 'http://www.omg.org/spec/DMN/20180521/DC/';

const id = 'AutoGeneratedDmn';

const name = 'DRD';

const namespace = 'http://camunda.org/schema/1.0/dmn';


// API //////////////////////////

/**
 * @param {Array} dmnContents
 * @returns {String}
 */
export const buildXmlFromDmnContent = (dmnContents) => {
  let base = generateBaseNodes();

  dmnContents.forEach(sheet => {
    const {
      name,
      rules,
      inputs,
      outputs,
      hitPolicy,
      aggregation
    } = sheet;

    // (1) generate decision table contents
    const ruleNodes = generateRuleNodes(rules);
    const inputNodes = generateInputNodes(inputs);
    const outputNodes = generateOutputNodes(outputs);
    const decisionTable = {
      '@id': nextId('DecisionTable_'),
      input: inputNodes,
      output: outputNodes,
      rule: ruleNodes
    };

    if (hitPolicy !== 'UNIQUE') {
      decisionTable['@hitPolicy'] = hitPolicy;
      if (aggregation) {
        decisionTable['@aggregation'] = aggregation;
      }
    }

    // (2) add to definitions
    const decision = {
      '@id': nextId('Decision_'),
      '@name': name,
      decisionTable
    };

    base.ele({ decision });
  });

  return base.end({ pretty: true });
};


// helper ///////////////////


const generateBaseNodes = () => {
  return builder.create({ 'definitions': { '@xmlns': xmlns,
    '@xmlns:dmndi': xmlnsDmndi,
    '@xmlns:dc': xmlnsDc,
    '@id': id,
    '@name':name,
    '@namespace': namespace } });
};

const generateRuleNodes = (rules = []) => {
  return rules.map(rule => {

    const inputEntries = rule.inputEntries.map(entry => {
      return {
        '@id': entry.id,
        'text': entry.text
      };
    });

    const outputEntries = rule.outputEntries.map(entry => {
      return {
        '@id': entry.id,
        'text': entry.text
      };
    });

    return {
      '@id': rule.id,
      description: rule.description,
      inputEntry: inputEntries,
      outputEntry: outputEntries
    };
  });
};

const generateInputNodes = (inputs = []) => {
  return inputs.map(input => {
    return {
      '@id': input.id,
      '@label': input.label,
      inputExpression: {
        '@id': input.inputExpression.id,
        '@typeRef': input.inputExpression.typeRef,
        text: input.inputExpression.text
      }
    };
  });
};

const generateOutputNodes = (outputs = []) => {
  return outputs.map(output => {
    return {
      '@id': output.id,
      '@label': output.text,
      '@name': output.name,
      '@typeRef': output.typeRef
    };
  });
};