const cloneDeep = require('lodash.clonedeep');

export const saveFileInLocalStorege = (type, tabItems, name, key, dataFile, edit) => {
	let clone = cloneDeep(tabItems);
	let fileList = localStorage.getItem(type)?JSON.parse(localStorage.getItem(type)):[];
	let index = clone.findIndex((el) => el.key === key);
	let str
	let date = new Date();
	if (name) {
		clone[index].label.props.children[1] = name;
		clone[index].label.props.children.splice(2, 1)
		if (fileList) {
			let indexObj = fileList.findIndex((el) => el.name === name)
			if (indexObj !== -1) {
				let obj = fileList[indexObj];
				obj.data = dataFile.data;
				obj.create = date;
				obj.id = dataFile.id;
				fileList.splice(indexObj, 1);
				fileList.unshift(obj)
			}
			else {
				fileList.unshift({ name: name, data: dataFile.data, create: date, id:dataFile.id });
			}
			str = JSON.stringify(fileList)
			localStorage.setItem(type, str);
		}
		else {
			str = JSON.stringify([{ name: name, data: dataFile.data, create: date, id:dataFile.id }])
			localStorage.setItem(type, str);
		}
	}
	if (edit) {
		let name  = clone[index].label.props.children[1];
		clone[index].label.props.children[1] = <span style={{ color: "red" }}>*</span>
		clone[index].label.props.children.push(name);
	}
	let result = { clone: clone, files: fileList }
	return result
}
export const deleteFileInLocalStorege = (type, name,) => {
	let fileList = localStorage.getItem(type)?JSON.parse(localStorage.getItem(type)):[];

	let index = fileList.findIndex((el) => el.name === name);

	fileList.splice(index, 1);

	let str = JSON.stringify(fileList)

	localStorage.setItem(type, str);

	let result = { files: fileList }
	return result
}	