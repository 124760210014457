import React, { Component } from "react";
import { Button, message, Modal, Input, Alert, Checkbox, Divider, Statistic, Popover, Table, Popconfirm, Tooltip } from 'antd';
import { SaveOutlined, DeleteOutlined,SaveFilled } from '@ant-design/icons';
import "@bpmn-io/form-js/dist/assets/dragula.css";
import "@bpmn-io/form-js/dist/assets/form-js.css";
import "@bpmn-io/form-js/dist/assets/form-js-editor.css";
import debounce from 'lodash/debounce';
import { generateID } from "../utils/generateID";
import MyFormViewer from "./savedFiles/MyFormViewer";
import { deleteFileInLocalStorege } from "../utils/FileFromLocalStorege";
import MyDiagramViewer from "./diagramView/MyDiagramViewer";
import MainContext from "../utils/Context";


	

class SaveFiles extends Component {
    static contextType = MainContext;
    constructor(props) {
        super(props);
        this.state = {
            savedFiles: [],
            nameFile: null,
            checkBox: false,
            validator: false,
            limitMemory: false,
            visible: false,
        };
        this.nameFile = debounce(this.nameFile, 400);
    }
    componentDidUpdate(prevProps) {
        if (this.context.activeIndex === this.props.index && this.context.eventModal === 'save' && !this.state.visible) {
           this.showModal();
          }
      }
    
    save = () => {
        let useName = this.state.savedFiles.find((el) => el.name === this.state.nameFile);
        if (useName && !this.state.checkBox) {
            this.setState({ validator: true })
        }
        else {
            this.props.save(this.state.nameFile)
            this.setState({
                visible: false
            })
            message.open({
                type: 'success',
                content: 'Saved',
                duration: 2
            });
        }
        if (this.context.eventModal === 'save') {
			this.context.offModal()
		}
    }
    showModal = () => {
        let fileList = [];
        let inLocalStorege = localStorage.getItem(this.props.type) ? JSON.parse(localStorage.getItem(this.props.type)) : [];
        if (inLocalStorege) {
            fileList = inLocalStorege
        }
        this.setState({ visible: true, savedFiles: fileList, nameFile: this.props.name })
    }
    handleCancel = () => {
        this.setState({ visible: false })
        if (this.context.eventModal === 'save') {
			this.context.offModal()
		}
    }
    nameFile = (name) => {
        let checkFormat = name.target.value.includes(this.props.format) ? name.target.value : name.target.value + this.props.format
        let useName = this.state.savedFiles.find((el) => el.name === checkFormat);
        if (useName) {
            this.setState({ nameFile: checkFormat, validator: true })
        }
        else if (this.state.savedFiles === 20) {
            this.setState({ nameFile: checkFormat, limitMemory: true, validator: false, })
        }
        else {
            let newName = name.target.value.includes(this.props.format)
            newName = newName ? name.target.value : checkFormat;
            this.setState({
                nameFile: newName,
                validator: false,
                checkBox: false,
                limitMemory: false
            })
        }
    }
    onChange = (e) => {
        this.setState({ checkBox: e.target.checked })
    }
    colorStatistic = (length) => {
        let color = 'black'
        if (length <= 10) {
            color = "#a0d911"
        }
        if (length > 10 && length < 15) {
            color = "#faad14"
        }
        if (length > 15) {
            color = "#f5222d"
        }
        return color
    }
    showPopover = (popover) => {
        this.setState({ popover })
    }
    deleteFile = (fileName) => {
        deleteFileInLocalStorege(this.props.type, fileName);
        let fileList = [];
        let inLocalStorege = localStorage.getItem(this.props.type) ? JSON.parse(localStorage.getItem(this.props.type)) : [];
        if (inLocalStorege) {
            fileList = inLocalStorege
        }
        this.setState({ savedFiles: fileList, limitMemory: false })
    }
    handleCancelPopconfirm = () => {
        this.setState({ open: false })
    }
    render = () => {

        let columns = [
            {
                dataSource: "name",
                key: "name",
                width: "60%",
                render: (text, record) => {
                    let content = null;
                    switch (this.props.type) {
                        case "savedForm":
                            content = <Popover
                                content={<MyFormViewer data={text.data} formId={"form" + generateID()} />}
                                title={text.name}
                                trigger="hover"
                                placement="right"
                                style={{ marginLeft: '200px' }}
                            >
                                <span >{text.name}</span>
                            </Popover>
                            break;
                        case "savedBpmn":
                            content = <Popover
                                content={<MyDiagramViewer data={text.data} />}
                                title={text.name}
                                trigger="hover"
                                placement="right"
                                style={{ marginLeft: '200px' }}
                            >
                                <span >{text.name}</span>
                            </Popover>
                            break;
                        default:
                            content = <span >{text.name}</span>
                            break;
                    }
                    return content
                },
            },
            {
                dataSource: "name",
                key: "name",
                width: "25%",
                render: (text, record) => {
                    return (
                        <Popconfirm
                            title="This is old version are you sure?"
                            open={this.state.open}
                            okText='Yes'
                            onConfirm={() => this.deleteFile(text)}
                            onCancel={this.handleCancelPopconfirm}
                        >
                            <Button type="link" ><DeleteOutlined style={{ fontSize: '14px', color: 'red' }} /> </Button>
                        </Popconfirm>

                    )
                },
            },
        ]
        let table = <Table
            showHeader={false}
            pagination={false}
            columns={columns}
            dataSource={this.state.savedFiles}
            size='small'
            rowKey="name"
            style={{ maxWidth: '250px' }}
            scroll={{
                y: 250,
                x: 'hiden'
            }}
        />
        let popoverInLimitMemory = <Popover
            content={table}
            title="Delete from local storage"
            trigger="click"
            placement="rightBottom"
            open={this.state.popover}
            onOpenChange={this.showPopover}
        >
            <Button type="link" className="btnPopover">Delete </Button>
        </Popover>
        const sharedProps = {
            style: {
                width: '100%',
            },
            defaultValue: this.state.nameFile,
        };
        let formStatistic = <Statistic
            value={this.state.savedFiles.length}
            valueStyle={{
                color: this.colorStatistic(this.state.savedFiles.length),
                fontSize: '16px',
            }}
            suffix="/ 20"
        />
        let modal =
            <Modal title="Save file" open={this.state.visible} destroyOnClose={true} onCancel={this.handleCancel} footer={false}>
                <Divider orientation="right" style={{ margin: '0' }}>{formStatistic}</Divider>
                <Alert
                    style={{ marginBottom: '20px', marginTop: '20px' }}
                    message="Informational Notes"
                    description="Additional description and information about copywriting."
                    type="info"
                    showIcon
                    closable
                />
                <div>Name file</div>
                <Input onChange={this.nameFile} {...sharedProps}></Input>
                {this.state.validator ? <Alert
                    style={{ marginTop: '20px' }}
                    message="File already exists!"
                    description="Rename the file to create new saved file or confirm overwrite."
                    type="warning"
                    showIcon
                /> : null}
                {this.state.limitMemory ? <> <Alert
                    style={{ marginTop: '20px' }}
                    message="Limit memory"
                    description={<span>This is a warning notice about copywriting.{popoverInLimitMemory} </span>}
                    type="error"
                    showIcon
                />
                </> : null}

                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                    {this.state.validator ? <Checkbox checked={this.state.checkBox} onChange={(e) => this.onChange(e)}>I want to overwrite the file</Checkbox> : null}
                    <Button type="primary" disabled={!this.state.nameFile || (this.state.validator && !this.state.checkBox) || this.state.limitMemory} onClick={() => this.save()}><SaveOutlined style={{ fontSize: '18px' }} />Save</Button>
                </div>
            </Modal>

        let button = this.props.mode ? <Button type="primary" onClick={() => this.showModal('save')}>Save</Button> :
            <Tooltip placement="top" title="Save">
                <Button type="link" onClick={() => this.showModal('save')}><SaveFilled style={{ color: '#595959', fontSize: '18px' }} /></Button>
            </Tooltip>


        return (
            <>
                {modal}
                {button}
            </>
        );
    };
}

export default SaveFiles;
