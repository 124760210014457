import { notification } from 'antd';
import Notifications from 'react-notification-system-redux';

export const showError = (dispatch, title, message = '', autoDismiss = 5, position = 'br') => {
    if(!!dispatch && !!title) {
        dispatch(Notifications.error({
            title: title,
            message: message,
            position: position,
            autoDismiss: autoDismiss
        }));
    }
};

export const showSuccess = (dispatch, title, message = '', autoDismiss = 5, position = 'br') => {
    if(!!dispatch && !!title) {
        dispatch(Notifications.success({
            title: title,
            message: message,
            position: position,
            autoDismiss: autoDismiss
        }));
    }
};

export const showWarning = (dispatch, title, message = '', autoDismiss = 5, position = 'br') => {
    if(!!dispatch && !!title) {
        dispatch(Notifications.warning({
            title: title,
            message: message,
            position: position,
            autoDismiss: autoDismiss
        }));
    }
};

export const showInfo = (dispatch, title, message = '', autoDismiss = 5, position = 'br') => {
    if(!!dispatch && !!title) {
        dispatch(Notifications.info({
            title: title,
            message: message,
            position: position,
            autoDismiss: autoDismiss
        }));
    }
};
export const notificationError = (title, message = '',notificationKey=1, autoDismiss = 5, position = 'bottomRight') => {
    if(!!title) {
       notification.error({
            message: title,
            key: notificationKey,
            description: message,
            placement: position,
            duration: autoDismiss
        });
    }
};

export const notificationSuccess = (title, message = '',notificationKey=2, autoDismiss = 5, position = 'bottomRight') => {
    if(!!title) {
        notification.success({
            message: title,
            key: notificationKey,
            description: message,
            placement: position,
            duration: autoDismiss
        });
    }
};

export const notificationWarning = (title, message = '',notificationKey=3, autoDismiss = 5, position = 'bottomRight') => {
    if(!!title) {
        notification.warning({
            message: title,
            key: notificationKey,
            description: message,
            placement: position,
            duration: autoDismiss
        });
    }
};

export const notificationInfo = (title, message = '',notificationKey=4, autoDismiss = 5, position = 'bottomRight') => {
    if(!!title) {
        notification.info({
            message: title,
            key: notificationKey,
            description: message,
            placement: position,
            duration: autoDismiss
        });
    }
};