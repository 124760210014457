import React, { Component } from "react";
import { Input, Modal, Select, Button, Form, Tooltip, Radio } from 'antd';
import { SendOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { isArray, } from "../../utils/JsObjectHelper";

class EmailTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            method: null,
            event: null,
            values: null,
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let activeElement = this.props.activeElement;
        if (activeElement && activeElement.type === 'bpmn:ServiceTask' && !this.state.visible && (activeElement.businessObject.delegateExpression === "${mailSenderWithEntryDelegate}" || activeElement.businessObject.delegateExpression === "${mailSenderDelegate}")) {
            let value = {};
            value.type = activeElement.businessObject.delegateExpression === "${mailSenderWithEntryDelegate}" ? "mailSenderWithEntryDelegate" : "mailSenderDelegate";
            let inputParameters = null;
            if (this.props.activeElement.businessObject.extensionElements) {
                inputParameters = this.props.activeElement.businessObject.extensionElements.values[0].inputParameters;
            }
            inputParameters.forEach((el) => value[el.name] = el.value);
            value.name = activeElement.businessObject.name;
            value.groupsId = value.receiverGroupIds ? value.receiverGroupIds.split(",") : undefined;
            value.userId = value.receiverUserIds ? value.receiverUserIds.split(",") : undefined;
            this.setState({
                values: value,
                visible: true
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false, values: null });
        this.props.clearElement()
    }
    showModal = (event) => {
        this.setState({
            visible: true,
            event: event
        });
    }
    method = (value) => {
        this.setState({
            method: value
        });
    }

    createEmailTask = (value) => {
        let groups = value.groupsId ? isArray(value.groupsId) ? value.groupsId.join() : value.groupsId : null;
        let users = value.userId ? isArray(value.userId) ? value.userId.join() : value.userId : null;

        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");
        let selection = this.props.modeler.get('selection');

        let ServiceTask = bpmnFactory.create('bpmn:ServiceTask');
        ServiceTask.definitionRef = 'modelerTemplate';

        ServiceTask.modelerTemplate = value.type === 'mailSenderDelegate' ? 'com.aphinit.bpmn.MailTaskImpl' : "com.aphinit.bpmn.MailTaskWithEntryImpl";
        ServiceTask.delegateExpression = value.type === 'mailSenderDelegate' ? '${mailSenderDelegate}' : "${mailSenderWithEntryDelegate}";

        let entryId = bpmnFactory.create('camunda:InputParameter', {
            name: "entryId",
            value: "${AP_ENTRY_ID}",
        });
        let emailSubject = bpmnFactory.create('camunda:InputParameter', {
            name: "emailSubject",
            value: value.emailSubject,
        });
        let sender = bpmnFactory.create('camunda:InputParameter', {
            name: "sender",
            value: value.sender,
        });
        let receiverUserIds = bpmnFactory.create('camunda:InputParameter', {
            name: "receiverUserIds",
            value: users
        });
        let receiverGroupIds = bpmnFactory.create('camunda:InputParameter', {
            name: "receiverGroupIds",
            value: groups
        });
        let messageBody = bpmnFactory.create('camunda:InputParameter', {
            name: "messageBody",
            value: value.messageBody
        });

        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [sender, receiverUserIds, receiverGroupIds, messageBody, entryId, emailSubject],
            outputParameters: []
        });
        if (value.type === 'mailSenderDelegate') {
            inputOutput = bpmnFactory.create('camunda:InputOutput', {
                inputParameters: [sender, receiverUserIds, receiverGroupIds, messageBody, emailSubject],
                outputParameters: []
            });
        }
        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
        extensionElements.set("values", [inputOutput]);
        const serviceTask = elementFactory.createShape({
            type: "bpmn:ServiceTask",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
            businessObject: ServiceTask
        });
        serviceTask.businessObject.set('name', value.name);
        serviceTask.businessObject.set("extensionElements", extensionElements);
        if (this.state.values) {
           let newElement = modeling.replaceShape(this.props.activeElement, serviceTask);
            selection.select(newElement);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [serviceTask], {
                hints: {
                    autoSelect: [serviceTask]
                }
            });
        }

        this.setState({
            visible: false, values: null
        });
    };


    render() {
        let optionsUser = [];
        let options = [];
        if (isArray(this.props.groupList)) {
            this.props.groupList.forEach((el) => {
                options.push({
                    value: el.id,
                    label: el.name
                })
            })
        }
        if (isArray(this.props.userList)) {
            this.props.userList.forEach((el) => {
                optionsUser.push({
                    value: el.id,
                    label: el.firstName + el.lastName
                })
            })
        }
        let modal = <Modal title="Create Send Email" destroyOnClose={true} open={this.state.visible} footer={false} onCancel={this.handleCancel} >
            <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.createEmailTask} autoComplete="off" initialValues={this.state.values}>
                <Form.Item
                    name='name'
                    label='Task name'
                    initialValue={this.state.values ? this.state.values.name : 'Send Email'}
                    rules={[
                        {
                            required: true,
                            message: 'Missing  name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='emailSubject'
                    label='Email subject'
                    initialValue={this.state.values ? this.state.values.emailSubject : 'info aphinit'}
                    rules={[
                        {
                            required: true,
                            message: 'Missing  email subject',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='sender'
                    label='Sender'
                    initialValue={this.state.values ? this.state.values.sender : 'Aphinit Modeler'}
                    rules={[
                        {
                            required: true,
                            message: 'Missing  sender',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='groupsId'
                    label='Recipients groups'
                >
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        options={options}>
                    </Select>
                </Form.Item>
                <Form.Item
                    name='userId'
                    label='Recipients people'>
                    <Select
                        mode="tags"
                        style={{ width: '100%' }}

                        options={optionsUser}
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    name="type"
                    label={<div><span style={{ marginRight: '10px' }}>Type</span><Tooltip placement="top" color='white' title={<span style={{ color: 'black' }}>This component is primary designed to use system variables(AP_ENTRY_ID, AP_INITIATOR) so it is not possible to run the process directly from Modeler environment.
                     If you are not using these variables in your process and wish to run the process directly, choose "Modeler run enabled" option. If this is not the case, choose "Aphinit run only" option. </span>}>
                        <InfoCircleOutlined style={{ color: 'silver' }} />
                    </Tooltip></div>}
                    rules={[
                        {
                            required: true,
                            message: 'Please pick an item!',
                        },
                    ]}
                >
                    <Radio.Group>
                        <Radio value="mailSenderWithEntryDelegate">Aphinit run only</Radio>
                        <Radio value="mailSenderDelegate">Modeler run enabled</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name='messageBody'
                    label='Message'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  message',
                        },
                    ]}
                >
                    <Input.TextArea style={{
                        height: 200,
                    }} />
                </Form.Item>

                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
        return <>{modal}<Tooltip placement="leftTop" title="Send email">
            <SendOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '20px', marginLeft: '3px' }} />
        </Tooltip>
        </>
    }
}

export default EmailTask