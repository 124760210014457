import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import { reducer as notifications } from 'react-notification-system-redux';
import { loggedUser } from "./loggedUser";
import { groupList } from "./groupList";
import { userList } from "./userList";
import { userCustomizations } from './userCustomizations';
import { objectList } from './objectList';

export const rootReducer = (history) => combineReducers({
    router: connectRouter(history),

    loggedUser,
    groupList,
    userList,
    userCustomizations,
    objectList
    
});