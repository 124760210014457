import React, { Component } from "react";
import { Button, Popover, Alert, Typography, Select, Switch } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
const { Paragraph } = Typography;

class EntryDetailOverlays extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            mode: true,
            selectedEntryType: null
        };
    }

    handleCancel = () => {
        this.setState({ visible: false });
    }
    showPopover = () => {
        this.setState({ visible: !this.state.visible });
    }
    changeMode = (checked) => {
        this.setState({ mode: checked });
    }
    selectEntryType =(value)=>{
        this.setState({ selectedEntryType: value });
    }
    render() {
        let attributes = [];
        let options = [];
        let content = null;
        if (this.props.entryTypeList) {
            options = this.props.entryTypeList.map((el) => { return { label: el.name, value: el.type } });
        }
        let baseInfo = [
            { id: "ID" },
            { type: "Type" },
            { name: "Name" },
            { version: 'Version' },
            { created: "Created" },
            { inserted: "Inserted" },
            { updated: "Updated" },
            { description: "Description" },
            { systemType: 'System type' },
            { userEntryAccess: "User entry access" },
            { createdByUserId: "Created by user Id" },
            { createdByUserName: "Created by user name" },
            { modifiedByUserId: "Modified by user Id" },
            { modifiedByUserName: "Modified by user name" }
        ];

        let switchMode = <Switch style={{ display: "flex", marginBottom: '10px' }} checkedChildren="Base info" unCheckedChildren="Attribute" onChange={this.changeMode} checked={this.state.mode} />
        if(this.props.entryTypeList && this.state.selectedEntryType){
            let EntryType = this.props.entryTypeList.find((el)=>el.type===this.state.selectedEntryType);
            if(EntryType){
                attributes = EntryType.properties.attributes.map(item => {
                    return { [item.techName]: item.name };
                });
            }
        }
        if (this.props.baseDetailVariableName && this.props.baseDetailVariableName.hasOwnProperty('value') && this.state.mode) {
            let nameBaseInfo = this.props.baseDetailVariableName.value;
            content = <div className="scrollable" style={{ maxHeight: '363px', overflow: 'auto' }}>{baseInfo.map((obj) => {
                const entries = Object.entries(obj);
                const [key, value] = entries[0];

                return <Paragraph key={key} copyable={{ text: "${" + nameBaseInfo + '["' + key + '"]}' }}>{value}</Paragraph>;
            })}</div>
        }
        else if (this.props.attributesVariableName && this.props.attributesVariableName.hasOwnProperty('value')&&!this.state.mode) {
            let nameAttributes = this.props.attributesVariableName.value;
            content = <div className="scrollable" style={{ maxHeight: '363px', overflow: 'auto' }}>
                <Select
                showSearch
                style={{
                    width: 200,
                    marginBottom:'10px'
                }}
                onChange={this.selectEntryType}
                placeholder="Search to entry type"
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={options}
            />
                {attributes.map((obj) => {
                    const entries = Object.entries(obj);
                    const [key, value] = entries[0];

                    return <Paragraph key={key} copyable={{ text: "${" + nameAttributes + '["' + key + '"]}' }}>{value}</Paragraph>;
                })}</div>
        }
        else {
            content = <Alert message="You did not specify a variable name to get information, fill in the Base detail and Properties fields." type="error" showIcon />
        }
        let mainContent = <>{switchMode}{content}</>
        let popover = <Popover
            open={this.state.visible}
            content={mainContent}
            onOpenChange={this.showPopover}
            title={"Copy variable name to clipboard"}
            placement="rightTop"
            trigger="click">
            <Button type="link" onClick={this.showPopover}><InfoCircleOutlined style={{ fontSize: '18px',color:'black' }} /></Button>
        </Popover>

        return <>{popover}</>
    }
}

export default EntryDetailOverlays