import React, { Component } from "react";
import { Button, Modal, message, Tabs, Typography, } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import UnifiedExpressionLanguage from "./UnifiedExpressionLanguage";
import ConnectorInfo from "./aphinitPanel/ConnectorInfo";
import ManualAphinitPanel from "./aphinitPanel/ManualAphinitPanel";
import multipleInstance from "./MultipleInstance";
const { Paragraph } = Typography;

class ManualMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            activeKey:"systemVariable",
        };
    }
 
    handleCancel = () => {
        this.setState({ visible: false });
    };
    showModal = () => {
        this.setState({ visible: true });
    };
    changeTab = (key)=>{
        this.setState({ activeKey: key });
    }
 

    render() {
       
        let items = [
            {
                label: "System variables",
                key: "systemVariable",
                children: <div >
                     <h2 style={{ fontWeight: 'bold' }}>System variables:</h2>
                    <Paragraph key="${AP_ENTRY_ID}" style={{fontSize:'16px'}} copyable={{ text: "${AP_ENTRY_ID}" }}>The entry on which the process was started <span style={{ fontWeight: 'bold' }}>{"${AP_ENTRY_ID}"}</span></Paragraph>
                    <Paragraph key="${AP_INITIATOR}" style={{fontSize:'16px'}} copyable={{ text: "${AP_INITIATOR}" }}>ID of the person who started the process <span style={{ fontWeight: 'bold' }}>{"${AP_INITIATOR}"}</span></Paragraph>
                </div>,
            },
            {
                label: "Unified Expression Language",
                key: "expression",
                children: UnifiedExpressionLanguage
            },
            {
                label: "Aphinit panel",
                key: "aphinitPanel",
                children: <ManualAphinitPanel/>
            },
            {
                label: "Multiple instance / Loops",
                key: "multipleInstance",
                children: multipleInstance
            }
        ]

        let modal = <Modal title="Manual" key={'manualModal'} destroyOnClose={true} width={1300} open={this.state.visible} onCancel={this.handleCancel} footer={false}>
            <div className="steps-content" >
                <Tabs
                    tabPosition="left"
                    items={items}
                    activeKey={this.state.activeKey}
                    onChange={this.changeTab}
                />
            </div>
        </Modal>

        return <>{modal}<Button type="link" onClick={this.showModal} style={{ color: 'black', marginTop: '10px' }}><QuestionCircleOutlined style={{ fontSize: '18px' }} /></Button></>
    }
}

export default ManualMain