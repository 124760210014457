import SetupPresenter from "../components/Setup";
import { connect } from "react-redux";
import { fetchUserCustomizations } from "../actions/userCustomizations";


const mapStateToProps = (state, ownProps) => {
	return {
        user: state.loggedUser.user,
	}
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
        reloadSetup: () => {
			dispatch(fetchUserCustomizations(true));
		},
		
	};
};

const Setup = connect(
	mapStateToProps,
	mapDispatchToProps
)(SetupPresenter);

export default Setup;
