import React, { Component } from "react";
import { Button, Row, Col, Tooltip } from 'antd';
import { ToolOutlined, StopOutlined,CloseOutlined} from '@ant-design/icons';
import UserTask from "./UserTask";
import BusinessRuleTask from "./BusinessRuleTask";
import Connector from "./Connector";
import EmailTask from "./EmailTask";
import PreviousTaskUserId from "./PreviousTaskUserId";
import ColectionId from "./ColectionId";
import CreateDraft from "./CreateDraft";
import MergeDraft from "./MergeDraft";
import CallActivity from "./CallActivity";
import EditStartEvent from "./EditStartEvent";
import Cat from '../bpmn-js-nyan/lib/nyan/cat/index';
import aphinit from "../image/aphinit.svg"
import CatMp3 from "../bpmn-js-nyan/cat.mp3"
import ChangeObjectRights from "./ChangeObjectRights";
import ChangeObjectStatus from "./ChangeObjectStatus";
import MainContext from "../../utils/Context";
import EntryIdsCollection from "./EntryIdsCollection";
import EntryDetail from "./EntryDetail";
import GetUserId from "./GetUserId";
import EntryEdit from "../../containers/aphinitToolsPanel/EntryEdit";
import EntryRelations from "./EntryRelations";
import ScriptTask from "./ScriptTask";
import EntryNew from "../../containers/aphinitToolsPanel/EntryNew";
class MainPanel extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);
    this.state = {
      activeElement: null,
    };
    this.audioEl = React.createRef();
    this.timerMessage = null;
  }
   playSound = () => {
    this.audioEl.current.play();
  };
  closeMessage = () => {
    this.context.showMessageCat(false);
    clearTimeout(this.timerMessage);
  }
  componentDidMount() {
    this.props.onMount();
  }
  changeElement = () => {
    let selection = this.props.modeler.get('selection');
    let activeElement = selection.get("selectedElements");
    if (activeElement.length === 1) {
      this.setState({
        activeElement: activeElement[0],
      });
    }
  }
  clearElement = () => {
    this.setState({ activeElement: null });
  }
  render() {
    const { test } = this.context;
    let panel =null;
    let groupList = this.props.groupListRequestResult.getState().isLoading() ? [] : this.props.groupListRequestResult.getData();
    let userList = this.props.userListRequestResult.getState().isLoading() ? [] : this.props.userListRequestResult.getData();
    let mode = null; 
    if (this.context.mySetup) {
      switch (this.context.mySetup.value.mode) {
        case "cat":
          mode = <img src={Cat.dataURL} className={"nyanCat"} alt="cat" width="60px" height="40px" onClick={this.playSound} />
          break;
        case "aphinit":
          mode = <img src={aphinit} alt="cat" width="60px" height="50px" />
          break;
        default:
          break;
      }
    }
    else{
      mode =<img src={aphinit} alt="cat" width="60px" height="50px"  />
    }
    if (this.context.visibleMessageCat) {
      this.timerMessage = setTimeout(this.closeMessage, 10000);
    }
    if(this.props.modeler){
      panel = <>
    <Row gutter={[10, 14]}>
      <Col span={10} style={{ marginLeft: '7px' }}><UserTask modeler={this.props.modeler} groupList={groupList} userList={userList} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
      <Col span={10}>  <BusinessRuleTask modeler={this.props.modeler} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
      <Col span={10} style={{ marginLeft: '7px' }}> <CallActivity modeler={this.props.modeler}  activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
      <Col span={10} > <ScriptTask modeler={this.props.modeler}  activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
    </Row>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
      <Tooltip placement="rightTop" title="Edit">
        <Button onClick={this.changeElement} style={{ backgroundColor: '#d9d9d9', borderRadius: '4px', width: '100%' }}>
          {!this.props.activeButton ? <StopOutlined style={{ fontSize: '18px', color: 'black' }} /> : <ToolOutlined style={{ fontSize: '22px', }} />}
        </Button>
      </Tooltip>
    </div>
    <hr key='hr2' style={{ borderTop: '1px solid #cccccc' }} />
    <Row gutter={[10, 14]}>
      <Col span={10} style={{ marginLeft: '7px' }}>  <ColectionId modeler={this.props.modeler} groupList={groupList} userList={userList} activeElement={this.state.activeElement} clearElement={this.clearElement}  /></Col>
      <Col span={10}> <Connector modeler={this.props.modeler} groupList={groupList} userList={userList}activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
      <Col span={10} style={{ marginLeft: '7px' }}>  <EmailTask modeler={this.props.modeler} groupList={groupList} userList={userList} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
      <Col span={10}>  <CreateDraft modeler={this.props.modeler} /></Col>
      <Col span={10} style={{ marginLeft: '7px' }}>  <MergeDraft modeler={this.props.modeler} /></Col>
      <Col span={10}> <PreviousTaskUserId modeler={this.props.modeler} groupList={groupList} userList={userList} activeElement={this.state.activeElement} clearElement={this.clearElement}/></Col>
      <Col span={10} style={{ marginLeft: '7px' }}> <ChangeObjectRights modeler={this.props.modeler} groupList={groupList} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
      <Col span={10}> <ChangeObjectStatus modeler={this.props.modeler} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
       <Col span={10} style={{ marginLeft: '7px' }}>  <EntryIdsCollection modeler={this.props.modeler} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
      <Col span={10}> <EntryDetail modeler={this.props.modeler} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
      <Col span={10} style={{ marginLeft: '7px' }}>  <GetUserId modeler={this.props.modeler} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col>
      <Col span={10}> <EntryRelations modeler={this.props.modeler} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col> 
      <Col span={10} style={{ marginLeft: '7px' }}> <EntryEdit modeler={this.props.modeler} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col> 
      <Col span={10}> <EntryNew modeler={this.props.modeler} activeElement={this.state.activeElement} clearElement={this.clearElement} /></Col> 
    </Row>
    <EditStartEvent modeler={this.props.modeler} activeElement={this.state.activeElement} clearElement={this.clearElement} />
   </> }
  
    let menu = <div style={{ backgroundColor: 'hsl(220deg 12% 95%)', width: '100px', justifyContent: "center" }} >
      <div style={{ display: 'flex', width: '20px', height: '40px', margin: '11.5px', }}>
        <Tooltip open={this.context.visibleMessageCat && this.context.activeIndex === this.props.index} placement="topRight" key='catMode' 
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Don't forget to save your work frequently so you don't lose it </span><CloseOutlined onClick={this.closeMessage} />
            </div>}>
          {mode} <audio ref={this.audioEl} src={CatMp3} />
        </Tooltip>
      </div>
      <hr key='hr' style={{ borderTop: '1px solid #cccccc' }} />
   {panel}
    </div>



    return <>{menu}</>
  }
}

export default MainPanel