import React, { Component } from "react";
import {  Modal,  Button,  Skeleton, Result } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

class TestConnector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            response: null,
            error: null
        };
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.values !== this.props.values && this.props.values && !this.state.visible) {
            this.setState({
                visible: true,
            });
            this.testRequest(this.props.values);
        }
    }
    copyValue = (value) => {
        function fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }
            document.body.removeChild(textArea);
        }

        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(value);
            return;
        }
        navigator.clipboard.writeText(value).then(function () {
            console.log('Async: Copying to clipboard was successful!');
        }, function (err) {
            console.error('Async: Could not copy text: ', err);
        });
    };

    testRequest = (values) => {
        /** @type RequestOptions */
       
        let options = {headers:{
            "Content-Type": values.contentType
        }}
        if(values.inputs && values.inputs.length >0){
            values.inputs.forEach((el)=>{
                options.headers[el.name] = el.value
            })
        }
        options.method = values.method;
        if(values.body ){
            let body = values.body
            .replace(/\\n/g, '\n')
            .replace(/^"|"$/g, '');
            try {
                options.body = body 
              } catch (error) {
                console.error('Неверный JSON:', error);
              }
        }
       fetch(values.url, options)
            .then(response => response.json())
            .then(data=> this.setState({ response: JSON.stringify(data, null, 2) }))
            .catch(error => {
                    if(error && error.response){
                       let errorObj ={
                        status: error.response.status,
                        message: error.response.message
                       }
                        this.setState({error:errorObj})
                    }
                    console.log(error);
                });
    }
    handleCancel = () => {
        this.setState({ visible: false, response: null,error:null });
    }
    render() {
        let modal = <Modal title="Response" width='60%'  destroyOnClose={true} open={this.state.visible} footer={false} onCancel={this.handleCancel}>
            {this.state.response ?<><Button type='default' style={{backgroundColor: "#f8f8f8",}} onClick={this.copyValue(this.state.response)}>Copy<CopyOutlined /></Button> <pre style={{height:'800px',overflow:'auto', padding: "1em", backgroundColor: "#f8f8f8", borderRadius: "5px", fontFamily: "monospace", whiteSpace: "pre-wrap"}}>{this.state.response}</pre></>:
            this.state.error?   <Result status={this.state.error.status} title={this.state.error.status} subTitle={this.state.error.message}  extra={<Button type="primary" onClick={this.handleCancel}>Back</Button>}/>:<Skeleton />} 
        </Modal>
        return modal 
    }
}
export default TestConnector