import React, { Component } from "react";
import { Input, Modal, Select, Button, Form, Tooltip,Space } from 'antd';
import { CodeOutlined,MinusCircleOutlined,PlusOutlined } from '@ant-design/icons';
import CodeEditor from "../CodeEditor/CodeEditor";
const { TextArea } = Input;
class ScriptTask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            method: null,
            event: null,
            values: null,
            script: '',
            scriptFormat: null,
            invalid: false,
        };

    }
    componentDidUpdate(prevProps, prevState) {
        let activeElement = this.props.activeElement
        if (activeElement && activeElement.type === "bpmn:ScriptTask" && !this.state.visible) {
            let arr = Object.entries(this.props.activeElement.businessObject);
            let value = {};
            arr.forEach((el) => {
                if (el[0] === 'documentation') {
                    value[el[0]] = el[1][0] && el[1][0].text ? el[1][0].text : '';
                }
                else {
                    value[el[0]] = el[1];
                }
            });
            if (value.extensionElements) {
                value.InputParameters = value.extensionElements.values[0].inputParameters;
                value.OutputParameters = value.extensionElements.values[0].outputParameters;
            }
            this.setState({
                values: value,
                visible: true,
                script: value.script
            })
        }
        if (prevState.scriptFormat !== this.state.scriptFormat &&prevState.scriptFormat !==null ) {
            this.setState({ script: "" });
        }
    }
    handleCancel = () => {
        this.setState({
            visible: false,
            values: null,
            script: '',
            scriptFormat: null,
            invalid: false
        });
        this.props.clearElement()
    }
    showModal = (event) => {
        this.setState({
            visible: true,
            event: event
        });
    }
    method = (value) => {
        this.setState({
            method: value
        })
    }


    createConnector = (value) => {
        if (this.state.script.trim().length === 0) {
            this.setState({ invalid: true });
        }
        else {
            const modeling = this.props.modeler.get('modeling');
            const bpmnFactory = this.props.modeler.get("bpmnFactory");
            const elementFactory = this.props.modeler.get("elementFactory");
            const create = this.props.modeler.get("create");
            let selection = this.props.modeler.get('selection');

            let ScriptTask = bpmnFactory.create("bpmn:ScriptTask");
            const scriptTask = elementFactory.createShape({
                type: "bpmn:ScriptTask",
                x: this.state.values ? this.props.activeElement.x + 50 : 0,
                y: this.state.values ? this.props.activeElement.y + 40 : 0,
                isExpanded: true,
                businessObject: ScriptTask
            });
            if (value.documentation) {
                const newDocumentation = bpmnFactory.create('bpmn:Documentation');
                newDocumentation.text = value.documentation;
                if (!Array.isArray(scriptTask.businessObject.documentation)) {
                    scriptTask.businessObject.documentation = [];
                }
                // Add the new documentation to the  call Activity
                scriptTask.businessObject.documentation.push(newDocumentation);
            }
            let inputOutput = bpmnFactory.create('camunda:InputOutput', {
                inputParameters: [],
                outputParameters: []
            });
            if (value.InputParameters) {
                value.InputParameters.forEach((el) => {
                    let input = bpmnFactory.create('camunda:InputParameter', {
                        name: el.name,
                        value: el.value
                    });
                    inputOutput.inputParameters.push(input)
                })
            }
            if (value.OutputParameters) {
                value.OutputParameters.forEach((el) => {
                    let output = bpmnFactory.create('camunda:OutputParameter', {
                        name: el.name,
                        value: el.value
                    });
                    inputOutput.outputParameters.push(output)
                })
            }
      
            const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
            extensionElements.set("values", [inputOutput]);
            scriptTask.businessObject.set("extensionElements", extensionElements);
            scriptTask.businessObject.set('name', value.name);
            scriptTask.businessObject.set('script', this.state.script);
            scriptTask.businessObject.set('scriptFormat', value.scriptFormat);
            scriptTask.businessObject.set('resultVariable', value.resultVariable);

            if (this.state.values) {
                let newElement = modeling.replaceShape(this.props.activeElement, scriptTask);
                selection.select(newElement);
                this.props.clearElement();
            }
            else {
                create.start(this.state.event, [scriptTask], {
                    hints: {
                        autoSelect: [scriptTask]
                    }
                });
            }
            this.setState({
                visible: false,
                values: null,
                script: '',
                scriptFormat: null,
                invalid: false
            });
        }
    };
    handleScriptChange = (code) => {
        this.setState({ script: code, invalid: false });

    }
    selectLanguage = (value) => {
        this.setState({ scriptFormat: value })
    }

    render() {

        let options = [
            {
                label: "javascript",
                value: 'javascript'
            },
            {
                label: "groovy",
                value: 'groovy'
            },
        ]
        let modal = <Modal title="Create script task" destroyOnClose={true} open={this.state.visible} footer={false} onCancel={this.handleCancel} >
            <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.createConnector} autoComplete="off" initialValues={this.state.values}>
                <Form.Item
                    name='name'
                    label='Task name'
                    rules={[
                        {
                            required: true,
                            message: 'Missing name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='documentation'
                    label='Documentation'
                >
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    name='scriptFormat'
                    label='Format'
                    rules={[
                        {
                            required: true,
                            message: 'Missing format',
                        },
                    ]}
                >
                    <Select
                        onChange={this.selectLanguage}
                        placeholder='Select programming language'
                        style={{ width: '100%' }}
                        options={options}>
                    </Select>
                </Form.Item>

                <CodeEditor script={this.state.script} invalid={this.state.invalid} scriptFormat={this.state.scriptFormat} isOpen={this.state.visible} handleScriptChange={this.handleScriptChange} />
                <Form.Item
                    name='resultVariable'
                    label='Result variable'>
                    <Input />
                </Form.Item>
                <h4>Inputs</h4>
                    <Form.List name="InputParameters" >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 8,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            label="Name variable"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Missing name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'value']}
                                            label="Value"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Missing value',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add field
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <h4>Outputs</h4>
                    <Form.List name="OutputParameters" >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 8,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            label="Name variable"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Missing name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'value']}
                                            label="Value"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Missing value',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add field
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
        return <>{modal}<Tooltip placement="rightTop" title="Script Task (inline)">
            <CodeOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '22px', marginLeft: '3px' }} />
        </Tooltip>
        </>
    }
}

export default ScriptTask