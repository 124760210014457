import React, { Component } from "react";
import { Input, Modal, Select, Form, DatePicker, Slider, Popover, Tooltip, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { isArray, } from "../../utils/JsObjectHelper";
import MyFormViewer from "../savedFiles/MyFormViewer";
import { generateID } from "../../utils/generateID";

class EditStartEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            event: null,
            formList: [],
            values: null
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let formList = localStorage.getItem("savedForm")?JSON.parse(localStorage.getItem("savedForm")):[];
        if (this.props.activeElement && this.props.activeElement.type === 'bpmn:StartEvent' && !this.state.visible) {
            let arr = Object.entries(this.props.activeElement.businessObject);
            let value = {}
            arr.forEach((el) => { value[el[0]] = el[1]})
            this.setState({
                values: value,
                visible: true,
                formList: formList 
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false, values: null });
        this.props.clearElement()
    }
    updateStartEvent = (value) => {
        value.formRefBinding="deployment"
        this.props.modeler.get('modeling').updateProperties(this.props.activeElement, value);
        this.setState({ visible: false, values: null });
        this.props.clearElement()
    }

    render() {
        let optionsForm = []
        if (this.state.formList.length > 0) {
            this.state.formList.forEach((el) => {
                optionsForm.push({
                    value: el.id,
                    label: <Popover
                        key={el.id}
                        content={<MyFormViewer data={el.data} formId={"form" + generateID()} />}
                        title={el.name}
                        trigger="hover"
                        placement="left"
                        style={{ marginRight: '200px' }}
                    >
                        <div>{el.name}</div>
                    </Popover>
                })
            });
        }
        let modal = null;
        if (this.state.visible) {
            modal = <Modal title="Start Event" footer={false} destroyOnClose={true} open={this.state.visible} onCancel={this.handleCancel} >
                <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.updateStartEvent} autoComplete="off" initialValues={this.state.values} >
                    <Form.Item
                        name='name'
                        label='Name'
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='formRef'
                        label='Form'
                    >
                        <Select
                            style={{ width: '100%' }}
                            options={optionsForm}>
                        </Select>
                    </Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'end', }}>
                        <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                            Cancel
                        </Button>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Ok
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        }

        return <>{modal}</>
    }
}

export default EditStartEvent