import React, { Component } from 'react'
import 'codemirror/lib/codemirror.css';
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/groovy/groovy';

export default class CodeEditor extends Component {
    codemirror = null;

    refreshEditor = () => {
        if (this.codemirror) {
            setTimeout(() => this.codemirror.refresh(), 300);
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen && this.props.isOpen) {
            this.refreshEditor();
        }
    }

    render() {
        const options = {
            lineNumbers: true,
            matchBrackets: true,
            mode: this.props.scriptFormat,
            theme: 'default',
            spellcheck:true
        };

        return (
            <div style={{marginBottom:"20px",}}>
                <span style={{color:'red'}}>* </span>
                <span>Script</span>
                <div style={{borderStyle:'solid',borderWidth:"1px",borderColor:this.props.invalid?'red':"#d9d9d9", borderRadius:'6px',marginTop:'8px',padding:'1px'}}>
                  
                    <CodeMirror
                        options={options}
                        value={this.props.script}
                        onBeforeChange={(editor, data, value) => {
                            this.props.handleScriptChange(value);
                        }}
                        editorDidMount={editor => { this.codemirror = editor; this.refreshEditor(); }}
                    />
                </div>
                {this.props.invalid? <span style={{color:'red', }} >Script cannot be empty</span>:null}
            </div>
        )
    }
}
