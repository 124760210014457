import React, { Component } from "react";
import { Button, Modal, message, Tabs, Typography, } from 'antd';
import { SolutionOutlined,ApiOutlined } from '@ant-design/icons';
import ConnectorInfo from "./ConnectorInfo";
import userIdsCollectionManual from "./userIdsCollectionManual";
const { Paragraph } = Typography;

class ManualAphinitPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }
 
    handleCancel = () => {
        this.setState({ visible: false });
    };
    showModal = () => {
        this.setState({ visible: true });
    };
 

    render() {
       
        let items = [
            {
                label: <span><SolutionOutlined style={{fontSize:'18px'}} />User IDs collection</span>,
                key: "userIdsCollection",
                children: userIdsCollectionManual
            },
            {
                label: <span><ApiOutlined style={{fontSize:'18px'}}/> Connector</span>,
                key: "connector",
                children: ConnectorInfo
            }
        ]

        let tabs = <Tabs
                    tabPosition="top"
                    items={items}
                />
          
       

        return <>{tabs}</>
    }
}

export default ManualAphinitPanel