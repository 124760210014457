import React, { Component } from "react";
import { Button, Upload, Popover, message, Form, Input, Checkbox, Select, Divider, InputNumber } from 'antd';
import { read } from "xlsx";
import { parseDmnContent } from "./converter/excelHandler";
import { buildXmlFromDmnContent } from "./converter/dmnXmlGenerator";
import excelIco from "./excel.png"

class DmnExcel extends Component {
    dmnEditor = null;
    constructor(props) {
        super(props);

        this.state = {
            buffer: [],
            fileList: null,
            workbook: null,
            popover: false,
            fileName: null
        };
    }
    beforeUpload = (file) => {
        const isXLSX = file.name.includes(".xlsx");
        if (!isXLSX) {
            message.error(`${file.name} is not a xlsx file`);
        }
        return isXLSX || Upload.LIST_IGNORE;
    };
    onFinish = (values) => {
        const { buffer } = this.state;
        const sheets = this.transformData(values);
        const dmnContent = parseDmnContent({ buffer, sheets });
        const dmnXml = buildXmlFromDmnContent(dmnContent);
        this.setState({
            popover: false,
            buffer: [],
            fileList: null,
            workbook: null,
            fileName: null
        });
        this.props.createNew("dmn",dmnXml,this.state.fileName);
    }
    processExcelData = (e) => {
        const buffer = new Uint8Array(e.target.result);
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: "array" });
        this.setState({ buffer: buffer, workbook: workbook.SheetNames });
    }

    handleOpenFile = (info) => {
        this.setState({ fileList: info.fileList[0], fileName: info.fileList[0].name });
        const file = info.fileList[0].originFileObj;
        const reader = new FileReader();
        reader.onload = this.processExcelData
        reader.readAsArrayBuffer(file)
    };


    handleVisibleChange = (popover) => {
        this.setState({ popover })
    }
    transformData = (inputObject) => {
        let result = [];
        let tempObj = {};
        for (const [key, value] of Object.entries(inputObject)) {
            let [tableName, property] = key.split('_');
            tempObj[property] = value;

            if (property === 'hitPolicy') {
                tempObj['tableName'] = tableName;
                if (tempObj['annotation']) {
                    tempObj['hasAnnotationColumn'] = tempObj['annotation'];
                    delete tempObj['annotation'];
                }
                if (tempObj['out']) {
                    tempObj['amountOutputs'] = tempObj['out'];
                    delete tempObj['out'];
                }
                if (tempObj['name']) {
                    tempObj['tableName'] = tempObj['name'];
                    delete tempObj['name'];
                }

                if (value === 'COLLECT_SUM') {
                    tempObj['aggregation'] = 'SUM';
                } else if (value === 'COLLECT_MIN') {
                    tempObj['aggregation'] = 'MIN';
                } else if (value === 'COLLECT_MAX') {
                    tempObj['aggregation'] = 'MAX';
                } else if (value === 'COLLECT_COUNT') {
                    tempObj['aggregation'] = 'COUNT';
                } else if (value === 'RULE_ORDER') {
                    tempObj['aggregation'] = 'RULE_ORDER';
                } else {
                    tempObj['aggregation'] = undefined;
                }

                result.push(tempObj);
                tempObj = {};
            }
        }
        return result;
    }


    render() {
        let options = [
            { value: 'UNIQUE', label: 'Unique' },
            { value: 'FIRST', label: 'First' },
            { value: 'PRIORITY', label: 'Priority' },
            { value: 'ANY', label: 'Any' },
            { value: 'COLLECT', label: 'Collect' },
            { value: 'COLLECT_SUM', label: 'Collect (Sum)' },
            { value: 'COLLECT_MIN', label: 'Collect (Min)' },
            { value: 'COLLECT_MAX', label: 'Collect (Max)' },
            { value: 'COLLECT_COUNT', label: 'Collect (Count)' },
            { value: 'RULE_ORDER', label: 'Rule order' },
            { value: 'OUTPUT_ORDER', label: 'Output order' }
        ];
        let content = <div style={{ maxWidth: '300px', }}>
            <Upload
                beforeUpload={this.beforeUpload}
                maxCount={1}
                showUploadList={false}
                onChange={this.handleOpenFile}
                accept=".xlsx"
            >
                <Button type="primary">Upload</Button>
            </Upload>
            {this.state.fileList ? <span style={{ marginLeft: "10px" }}>{this.state.fileList.name}</span> : null}
            {this.state.workbook ? <Form name="basic" layout="vertical" onFinish={this.onFinish} autoComplete="off">
                <div style={{ maxHeight: '550px', width: '290px', overflow: "auto" }}>
                    {this.state.workbook.map((sheet, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Divider />
                                <h4>Excel sheet name - {sheet}</h4>
                                <Form.Item label="Decision table name" initialValue={sheet} name={sheet + '_name'}>
                                    <Input style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item label="Amount output columns" initialValue={1} name={sheet + '_out'}>
                                    <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                                <Form.Item
                                    label="Has annotation column?"
                                    name={sheet + '_annotation'}
                                    valuePropName="checked"
                                    initialValue={false}
                                >
                                    <Checkbox />
                                </Form.Item>
                                <Form.Item name={sheet + '_hitPolicy'} initialValue={options[0].value} label="Hit policy">
                                    <Select style={{ width: "100%" }} options={options} />
                                </Form.Item>
                            </React.Fragment>
                        );
                    })}
                </div>
                <Button style={{ width: "100%" }} type="primary" htmlType="submit">Import</Button>
            </Form> : null}
        </div>


        return <Popover content={content}
            open={this.state.popover}
            onOpenChange={this.handleVisibleChange}
            title="Import Excel Sheet"
            trigger="click" >
            <img src={excelIco} alt="Logo" width="18px" height="18px" />
        </Popover>



    }
}

export default DmnExcel