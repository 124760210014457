import React from 'react';
import { Alert } from 'antd';
import BpmnJS from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js';


export default class MyDiagramViewer extends React.Component {

	constructor(props) {
		super(props);

		this.state = {};

		this.containerRef = React.createRef();
	}

	componentDidMount() {
		const container = this.containerRef.current;

		this.bpmnViewer = new BpmnJS({ container });

		this.bpmnViewer.on('import.done', (event) => {
			const {
				error,
				warnings
			} = event;

			if (error) {
				return this.handleError(error);
			}

			this.bpmnViewer.get('canvas').zoom('fit-viewport');

			return this.handleShown(warnings);
		});


		if (this.props.data) {
			return this.displayDiagram(this.props.data);
		}
	}

	componentWillUnmount() {
		this.bpmnViewer.destroy();
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			props,
			state
		} = this;

		if (props.url !== prevProps.url) {
			return this.fetchDiagram(props.url);
		}

		const currentXML = props.diagramXML || state.diagramXML;

		const previousXML = prevProps.diagramXML || prevState.diagramXML;

		if (currentXML && currentXML !== previousXML) {
			return this.displayDiagram(currentXML);
		}
	}

	displayDiagram(diagramXML) {
		this.bpmnViewer.importXML(diagramXML);
	}


	handleLoading() {
		const { onLoading } = this.props;

		if (onLoading) {
			onLoading();
		}
	}

	handleError(err) {
		const { onError } = this.props;

		if (onError) {
			onError(err);
		}
	}

	handleShown(warnings) {
		const { onShown } = this.props;

		if (onShown) {
			onShown(warnings);
		}
	}

	render() {
		return (
			<div className="react-bpmn-diagram-container" key={this.props.formId} ref={this.containerRef}></div>
		);
	}
}