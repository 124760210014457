import EntryEditPresenter from '../../components/aphinitToolsPanel/EditEntry/EntryEdit';
import { connect } from 'react-redux';
import {fetchObjectList} from "../../actions/objectList";



const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
        },
    }
};

const EntryEdit = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryEditPresenter);

export default EntryEdit;
