import 'codemirror/lib/codemirror.css';
import { Controlled as CodeMirror } from 'react-codemirror2'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/groovy/groovy';
import 'codemirror/theme/monokai.css';
const options = {
    viewportMargin: Infinity,
    matchBrackets: true,
    mode: "javascript",
    theme: 'monokai',
    spellcheck: true
};

let ConnectorInfo = <><h2> HTTP Connector</h2>

    <p>The  HTTP Connector allows you to integrate business processes with external HTTP services. It supports all the major HTTP methods, including:</p>

    <ul>
        <li><b>GET</b>: Used to request data from a server.</li>
        <li><b>POST</b>: Used to send new data to a server.</li>
        <li><b>PUT</b>: Used to update existing data on a server.</li>
        <li><b>DELETE</b>: Used to remove data from a server.</li>
    </ul>

    <p>When using POST or PUT methods, you can send data in the request body. This data can be in any format, but the most common are JSON and XML.</p>

    <p>For example:</p>


    <CodeMirror
        options={options}
        value={
`{
    "key": "value",
    "anotherKey": "anotherValue"
}`}
    />
    <p>After the request is executed, the result will be available in the `Response` variable that you will name when setting up the connector. This is usually JSON or XML data returned by the server. The returned data can be used in subsequent elements of the process.</p>

    <p>For example:</p>
    <CodeMirror
        options={options}
        value={
`{
    "responseKey": "responseValue",
    "anotherResponseKey": "anotherResponseValue"
}`}
    /></>
export default ConnectorInfo;