import {createBaseOptions, fetchJson, METHOD_GET, METHOD_POST} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const GetXml = (id,callback) => {
    
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_GET;
    options.headers["Content-Type"] = "application/json";
   
   
 let res = fetchJson(`${config.url.API_URL}/bpmn/process-definition/${id}/xml `, options)
               res.then(result => {
                 return result
                }).catch(err => {
                    console.log(err);
                })
              return res
              
};






