import React, { Component } from "react";
import { Input, Modal, Select, Form, DatePicker, Slider, Popover, Tooltip, Button, Space } from 'antd';
import { UserOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { isArray, } from "../../utils/JsObjectHelper";
import moment from "moment/moment";
import MyFormViewer from "../savedFiles/MyFormViewer";
import { generateID } from "../../utils/generateID";
const { TextArea } = Input;
class UserTask extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            event: null,
            formList: [],
            values: null
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let formList = localStorage.getItem("savedForm") ? JSON.parse(localStorage.getItem("savedForm")) : [];
        if (formList && formList.length !== this.state.formList.length) {
            this.setState({ formList: formList });
        }
        if (this.props.activeElement && this.props.activeElement.type === 'bpmn:UserTask' && !this.state.visible) {
            let arr = Object.entries(this.props.activeElement.businessObject);
            let value = {}
            arr.forEach((el) => {
                if (el[0] === 'dueDate' || el[0] === 'followUpDate') {
                    value[el[0]] = moment(el[1]);
                } else if (el[0] === 'candidateGroups' || el[0] === 'candidateUsers') {
                    value[el[0]] = el[1].split(",")
                }
                else if(el[0] === 'assignee'){
                    value[el[0]] =[ el[1]]
                }
                else if(el[0] === 'documentation'){
                    value[el[0]]= el[1][0] && el[1][0].text? el[1][0].text:'';
                }
                else {
                    value[el[0]] = el[1];
                }
            });
            if (value.extensionElements) {
                value.InputParameters = value.extensionElements.values[0].inputParameters;
                value.OutputParameters = value.extensionElements.values[0].outputParameters;
            }
            this.setState({
                values: value,
                visible: true
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false, values: null });
        this.props.clearElement()
    }
    showModal = (event) => {
        let inLocalStorege = localStorage.getItem("savedForm") ? JSON.parse(localStorage.getItem("savedForm")) : [];
        this.setState({
            visible: true,
            event: event,
            formList: inLocalStorege
        });
    }
    createUserTask = (value) => {
        let groups = value.candidateGroups ? isArray(value.candidateGroups) ? value.candidateGroups.join() : value.candidateGroups : null;
        let users = value.candidateUsers ? isArray(value.candidateUsers) ? value.candidateUsers.join() : value.candidateUsers : null;
        let dueDate = value.dueDate ? value.dueDate.format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null;
        let followUpDate = value.followUpDate ? value.followUpDate.format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null;
        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");
        let selection = this.props.modeler.get('selection');

        const UserTask = bpmnFactory.create("bpmn:UserTask");

        UserTask.set('name', value.name);
        if (value.assignee) {
            UserTask.set('assignee', value.assignee[0]);
        }
        if (groups) {
            UserTask.set('candidateGroups', groups);
        }
        if (users) {
            UserTask.set('candidateUsers', users);
        }
        if (dueDate) {
            UserTask.set('dueDate', dueDate);
        }
        if (followUpDate) {
            UserTask.set('followUpDate', followUpDate);
        }
        if(value.documentation){
            const newDocumentation = bpmnFactory.create('bpmn:Documentation');
            newDocumentation.text = value.documentation;
            
            // Add the new documentation to the UserTask
            UserTask.get('documentation').push(newDocumentation);
        }
       
        UserTask.set('priority', value.priority);
        UserTask.set('formRef', value.formRef);
        UserTask.set('formRefBinding', 'deployment');
        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [],
            outputParameters: []
        });
        if (value.InputParameters) {
            value.InputParameters.forEach((el) => {
                let input = bpmnFactory.create('camunda:InputParameter', {
                    name: el.name,
                    value: el.value
                });
                inputOutput.inputParameters.push(input)
            })
        }
        if (value.OutputParameters) {
            value.OutputParameters.forEach((el) => {
                let output = bpmnFactory.create('camunda:OutputParameter', {
                    name: el.name,
                    value: el.value
                });
                inputOutput.outputParameters.push(output)
            })
        }
  
        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
        extensionElements.set("values", [inputOutput]);

        const userTask = elementFactory.createShape({
            type: "bpmn:UserTask",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
            businessObject: UserTask
        });
        userTask.businessObject.set("extensionElements", extensionElements);
        if (this.state.values) {
            let newElement = modeling.replaceShape(this.props.activeElement, userTask);
            selection.select(newElement);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [userTask], {
                hints: {
                    autoSelect: [userTask]
                }
            });
        }

        this.setState({
            visible: false, values: null
        });

    }
    validateTagSelection = (rule,value ) => {
        if (value && isArray(value) && value.length > 1) {
          return Promise.reject(new Error('You can select only one person'));
        }
        return Promise.resolve();
      };
      
    render() {
        let optionsUser = [];
        let options = [];
        let optionsForm = []
        if (this.state.formList.length > 0) {
            this.state.formList.forEach((el) => {
                optionsForm.push({
                    value: el.id,
                    label: <Popover
                        key={el.id}
                        content={<MyFormViewer data={el.data} formId={"form" + generateID()} />}
                        title={el.name}
                        trigger="hover"
                        placement="left"
                        style={{ marginRight: '200px' }}
                    >
                        <div>{el.name}</div>
                    </Popover>
                })
            });
        }

        if (isArray(this.props.groupList)) {
            this.props.groupList.forEach((el) => {
                options.push({
                    value: el.id,
                    label: el.name
                })
            })
        }
        if (isArray(this.props.userList)) {
            this.props.userList.forEach((el) => {
                optionsUser.push({
                    value: el.id,
                    label: el.firstName + el.lastName
                })
            })
        }
        let modal = null;
        if (this.state.visible) {
            modal = <Modal title={this.state.values ? "Edit User Task" : "Create User Task"} footer={false} destroyOnClose={true} open={this.state.visible} onCancel={this.handleCancel} >
                <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.createUserTask} autoComplete="off" initialValues={this.state.values} >
                    <Form.Item
                        name='name'
                        label='Task name'
                        rules={[
                            {
                                required: true,
                                message: 'Missing  name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='formRef'
                        label='Form'
                        rules={[
                            {
                                required: true,
                                message: 'Missing  form',
                            },
                        ]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            options={optionsForm}>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='documentation'
                        label='Documentation'
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                        name='assignee'
                        label='Assignee'
                        rules={[
                            {
                              validator: this.validateTagSelection,
                            },
                          ]}
                    >
                        <Select
                        mode="tags"
                            style={{ width: '100%' }}
                            options={optionsUser}>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='candidateGroups'
                        label='Candidate groups'
                    >
                        <Select
                        mode="tags"
                            style={{ width: '100%' }}
                            options={options}>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name='candidateUsers'
                        label='Candidate users'>
                        <Select
                            mode="tags"
                            style={{ width: '100%' }}

                            options={optionsUser}
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name='dueDate'
                        label='Due date'
                    >
                        <DatePicker
                            allowClear={false}
                            format="YYYY-MM-DD HH:mm:ss"

                            showTime={{
                                defaultValue: moment('00:00:00', 'HH:mm:ss'),
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name='followUpDate'
                        label='Follow up date'
                    >
                        <DatePicker
                            allowClear={false}
                            format="YYYY-MM-DD HH:mm:ss"
                            onChange={this.changeFollowUPDate}
                            showTime={{
                                defaultValue: moment('00:00:00', 'HH:mm:ss'),
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name='priority'
                        label='Priority'

                    >
                        <Slider marks={{
                            0: {
                                style: {
                                    color: 'silver',
                                },
                                label: <strong>0</strong>,
                            },
                            25: {
                                style: {
                                    color: '#f8e71c',
                                },
                                label: <strong>25</strong>,
                            },
                            50: {
                                style: {
                                    color: '#f8e71c',
                                },
                                label: <strong>50</strong>,
                            },
                            75: {
                                style: {
                                    color: 'orange',
                                },
                                label: <strong>75</strong>,
                            },
                            100: {
                                style: {
                                    color: '#f50',
                                },
                                label: <strong>100</strong>,
                            },
                        }
                        } />
                    </Form.Item>
                    <h4>Inputs</h4>
                    <Form.List name="InputParameters" >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 8,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            label="Name variable"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Missing name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'value']}
                                            label="Value"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Missing value',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add field
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <h4>Outputs</h4>
                    <Form.List name="OutputParameters" >
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            marginBottom: 8,
                                        }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'name']}
                                            label="Name variable"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Missing name',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'value']}
                                            label="Value"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Missing value',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add field
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <div style={{ display: 'flex', justifyContent: 'end', }}>
                        <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                            Cancel
                        </Button>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Ok
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>
        }

        return <>{modal}<Tooltip placement="leftTop" title="User Task">
            <UserOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '22px', marginLeft: '3px' }} placeholder="user task" />
        </Tooltip></>
    }
}

export default UserTask