import React, { Component } from "react";
import { Input, Modal, Button, Form, Tooltip,Alert } from 'antd';
import { UserSwitchOutlined, } from '@ant-design/icons';
import { isArray, } from "../../utils/JsObjectHelper";

class PreviousTaskUserId extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            method: null,
            event: null,
            values: null
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let activeElement =this.props.activeElement
        if (activeElement && activeElement.type === 'bpmn:ServiceTask' && !this.state.visible && activeElement.businessObject.delegateExpression === "${PreviousTaskUserID}") {
            let value = {};
            let inputParameters = null;
            if(this.props.activeElement.businessObject.extensionElements){
                inputParameters = this.props.activeElement.businessObject.extensionElements.values[0].inputParameters;
            }
            value.name = activeElement.businessObject.name;
           inputParameters.forEach((el)=> value[el.name] = el.value );
            this.setState({
                values: value,
                visible: true
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false ,values:null });
        this.props.clearElement()
    }
    showModal = (event) => {
        this.setState({
            visible: true,
            event: event
        });
    }
    method = (value) => {
        this.setState({
            method: value
        })
    }

    createConnector = (value) => {
        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");
        let selection = this.props.modeler.get('selection');

        let ServiceTask = bpmnFactory.create('bpmn:ServiceTask');
        ServiceTask.definitionRef = 'modelerTemplate';
        ServiceTask.modelerTemplate = "com.aphinit.bpmn.PreviousTaskUserID";
        ServiceTask.delegateExpression = "${PreviousTaskUserID}";



        let nameVariable = bpmnFactory.create('camunda:InputParameter', {
            name: "nameVariable",
            value: value.nameVariable,
        });

        ServiceTask.set('name', value.name);
        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [nameVariable],
            outputParameters: []
        });
        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
        extensionElements.set("values", [inputOutput]);
        const serviceTask = elementFactory.createShape({
            type: "bpmn:ServiceTask",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
            businessObject: ServiceTask
        });
        serviceTask.businessObject.set('name', value.name);
        serviceTask.businessObject.set("extensionElements", extensionElements);
        if (this.state.values) {
           let newElement = modeling.replaceShape(this.props.activeElement, serviceTask);
            selection.select(newElement);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [serviceTask], {
                hints: {
                    autoSelect: [serviceTask]
                }
            });
        }
        this.setState({
            visible: false,values:null
        });
    };


    render() {
        let optionsUser = [];
        let options = [];
        if (isArray(this.props.groupList)) {
            this.props.groupList.forEach((el) => {
                options.push({
                    value: el.id,
                    label: el.name
                })
            })
        }
        if (isArray(this.props.userList)) {
            this.props.userList.forEach((el) => {
                optionsUser.push({
                    value: el.id,
                    label: el.firstName + el.lastName
                })
            })
        }
        let modal = <Modal title="Create Previous Task User Id" destroyOnClose={true} open={this.state.visible} footer={false} onCancel={this.handleCancel} >
            <Alert
                description="Name a variable that will contain the Id of the person who completed the previous task."
                type="info"
                showIcon
            />
            <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.createConnector} autoComplete="off" initialValues={this.state.values}>
            <Form.Item
                    name='name'
                    label='Task name'
                    initialValue={"Previous Task User Id"}
                    rules={[
                        {
                            required: true,
                            message: 'Missing name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='nameVariable'
                    label='Variable name'
                    rules={[
                        {
                            required: true,
                            message: 'Missing variable name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
        return <>{modal}<Tooltip placement="rightTop" title="Previous Task UserId ">
            <UserSwitchOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '22px', marginLeft: '3px' }} />
        </Tooltip>
        </>
    }
}

export default PreviousTaskUserId