import React, { Component } from "react";
import { Button, InputNumber, Modal, Checkbox,Form,Radio,message } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import Cat from './bpmn-js-nyan/lib/nyan/cat/index';
import aphinit from "./image/aphinit.svg"
import { fetchNewUserCustomizations } from "../apicalls/fetchNewUserCustomizations";
import { fetchUpdateUserCustomizations } from "../apicalls/fetchUpdateUserCustomizations";

class Setup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            defaultSettup: {lint:true,notification:10,mode:'aphinit',tips:true,},
        };
    }
    componentDidMount = () =>{
        if(this.props.mySetup){
            this.setState({defaultSettup: this.props.mySetup.value})
        }
    }
    componentDidUpdate = (prevProps,prevState)=>{
        if(prevProps.mySetup !== this.props.mySetup ){
            if(this.props.mySetup){
                this.setState({defaultSettup: this.props.mySetup.value})
            }
        }
    }
    handleCancel = () => {
        this.setState({ visible: false });
    };
    showModal = () => {
        this.setState({ visible: true });
    };
    confirm = () => {
        message.open({
            type: 'success',
            content: 'Saved successfully',
            className: 'custom-class',
            style: {
              marginTop: '10vh',
            },
          });
        this.props.reloadSetup();
        this.setState({ visible: false });
    };
    saveSetup = (value)=>{
        let settings = { userId: this.props.user.id, type: 'modelerSetup', name: "modelerSetup_" + this.props.user.username, value: value }
        if(this.props.mySetup){
            settings.id = this.props.mySetup.id;
            fetchUpdateUserCustomizations(settings,() => this.confirm());
        }else{
            fetchNewUserCustomizations(settings,() => this.confirm());
        }

      
    }
    render() {
        const options = [
            {
              label:<img src={aphinit} alt="cat" width="30px" height="25px" />,
              value: 'aphinit',
            },
            {
              label: <img src={Cat.dataURL} alt="cat" width="50px" height="30px" />,
              value: 'cat',
            },
          ];
          
        let modal = <Modal title="Setup" key={'setupModal'} destroyOnClose={true} style={{ overflowY: "800px", maxHeigth: '800px' }} open={this.state.visible} onCancel={this.handleCancel} footer={false}>
            <div className="steps-content">
            <Form name="dynamic_form_nest_item" initialValues={this.state.defaultSettup} layout="horizontal" onFinish={this.saveSetup} autoComplete="off">
             
                <Form.Item
                    name='mode'
                    label='Mode'>
                    <Radio.Group options={options} optionType="button" />
                </Form.Item>
                <Form.Item
                    name='notification'
                    label='Save reminder (mins)'>
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    name='tips'
                    label='Aphinit tips enabled'
                    valuePropName="checked">
                   <Checkbox defaultChecked={this.state.defaultSettup.tips} ></Checkbox>
                </Form.Item>
                <Form.Item
                    name='lint'
                    label='BPMN check'
                    valuePropName="checked">
                   <Checkbox  ></Checkbox>
                </Form.Item>
                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                           Save
                        </Button>
                    </Form.Item>
                </div>
            </Form>
            </div>
        </Modal>

        return <>{modal}<Button type="link" onClick={this.showModal} style={{ color: 'black', marginTop: '10px' }}><SettingOutlined style={{ fontSize: '18px' }}/></Button></>
    }
}

export default Setup