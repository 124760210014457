import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout, } from 'antd';
import 'antd/dist/reset.css';
import Main from './containers/Main';
const { Content } = Layout;



class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

    };

    this.onLogout = this.onLogout.bind(this);
  }
  onLogout = () => {
    this.props.onLogout(this.props.keycloak);
  };
  render() {
    if(this.props.token == null) return <div />;
    return (
      <Layout>
        <Content style={{ backgroundColor: 'white' }}>
          <Router>
            <Routes>
              <Route path="/" element={<Main onLogout={this.onLogout}  />} />
            </Routes>
          </Router>

        </Content>
      </Layout>


    );
  }
}

export default App;
