import React, { Component } from "react";
import { Tooltip, } from 'antd';
import { FileAddOutlined , } from '@ant-design/icons';

class CreateDraft extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    createDraft = (event) => {
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");


        let ServiceTask = bpmnFactory.create('bpmn:ServiceTask');
        ServiceTask.definitionRef = 'modelerTemplate';
        ServiceTask.modelerTemplate = "com.aphinit.bpmn.CreateDraftObject";
        ServiceTask.delegateExpression = "${createEntryDraftDelegate}";

        const serviceTask = elementFactory.createShape({
            type: "bpmn:ServiceTask",
            x: 0,
            y: 0,
            isExpanded: true,
            businessObject: ServiceTask
        });
        serviceTask.businessObject.set('name', 'Entry draft');
        create.start(event, [serviceTask], {
            hints: {
                autoSelect: [serviceTask]
            }
        });

        this.setState({
            visible: false,
        });
    };


    render() {
        return <><Tooltip placement="rightTop" title="Entry dtraft">
            <FileAddOutlined  onClick={(event) => this.createDraft(event)} style={{ fontSize: '22px', marginLeft: '3px' }} />
        </Tooltip>
        </>
    }
}

export default CreateDraft