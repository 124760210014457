import { Typography, } from 'antd';
const { Paragraph } = Typography;
let regExpressions = [
    {
        "label": "Comparison of two variables:",
        "reg": "#{variable1 == variable2}"
    },
    {
        "label": "Equality check with a constant:",
        "reg": "#{variable == 'constant'}"
    },
    {
        "label": "Inequality check:",
        "reg": "#{variable != 'constant'}"
    },
    {
        "label": "Complex conditions:",
        "reg": "#{variable1 == 'constant' and variable2 > 5}"
    },
    {
        "label": "Check for emptiness:",
        "reg": "#{empty variable}"
    },
    {
        "label": "Accessing properties of an object:",
        "reg": "#{variable.property == 'constant'}"
    },
    {
        "label": "Accessing elements of a list or array:",
        "reg": "#{array[1] == 'constant'}"
    },
    {
        "label": "Accessing elements of a Map:",
        "reg": "#{map['key'] == 'constant'}"
    }
]
let UnifiedExpressionLanguage = <div >
<h2 style={{ fontWeight: 'bold' }}>Introduction to Regular Expressions in our Modeler:</h2>
<div style={{fontSize:'16px'}}>Regular expressions (regex) are powerful tools that we use in our Aphinit Modeler for pattern matching and manipulation of strings. They can greatly enhance the flexibility and functionality of our workflows.
    Here are some examples of regular expressions we frequently use:</div>

{regExpressions.map((el) => { return <><span style={{ fontWeight: 'bold' ,fontSize:'16px'}} >{el.label}</span> <Paragraph copyable={{ text: el.reg }}  style={{fontSize:'16px'}}>{el.reg}</Paragraph></> })}

<div style={{fontSize:'16px'}}>
    <p>These are just a few examples. Regular expressions can be far more complex and powerful.</p>
    <p>For learning more about regular expressions and their use, I recommend the following resources:</p>
    <ul>
        <li><a href="https://www.regular-expressions.info/" target="_blank" rel="noopener noreferrer">Regular-Expressions.info</a>: A comprehensive guide to regular expressions with tutorials and examples.</li>
        <li><a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions" target="_blank" rel="noopener noreferrer">MDN Regular Expressions Guide</a>: A guide from Mozilla Developer Network focused on JavaScript regular expressions but applicable broadly.</li>
    </ul>
    <p>Remember that the best way to master regular expressions is through practice and experimentation. Happy modeling!</p>
</div>

</div>
export default UnifiedExpressionLanguage