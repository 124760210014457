import React, { Component } from "react";
import { Input, Modal, Select, Form, Button, Space, Tag, Tooltip } from 'antd';
import { InsertRowBelowOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { isUndefined, } from "../../utils/JsObjectHelper";
const { TextArea } = Input;

class BusinessRuleTask extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            values: null,
            dmnList: [],
            event: null,
            dmnFile: null,
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let dmnList = localStorage.getItem("savedDmn") ? JSON.parse(localStorage.getItem("savedDmn")) : [];
        if (dmnList && dmnList.length !== this.state.dmnList.length) {
            this.setState({ dmnList: dmnList });
        }
        if (this.props.activeElement && this.props.activeElement.type === 'bpmn:BusinessRuleTask' && !this.state.visible) {
            let arr = Object.entries(this.props.activeElement.businessObject);
            let value = {};
            arr.forEach((el) => {
                if (el[0] === 'documentation') {
                    value[el[0]] = el[1][0] && el[1][0].text ? el[1][0].text : '';
                }
                else {
                    value[el[0]] = el[1]
                }

            });
            let file = dmnList.find((element) => element.id.find((dec) => dec.id === value.decisionRef));
            if (file) {
                value.file = file.name
            }
            if (value.extensionElements) {
                value.InputParameters = value.extensionElements.values[0].inputParameters;
                value.OutputParameters = value.extensionElements.values[0].outputParameters;
            }
            if (isUndefined(value.mapDecisionResult)) {
                value.mapDecisionResult = "resultList"
            }


            this.setState({
                values: value,
                visible: true
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false, values: null });
        this.props.clearElement();
    }
    showModal = (event) => {
        let dmnList = [];
        let inLocalStorege = localStorage.getItem("savedDmn") ? JSON.parse(localStorage.getItem("savedDmn")) : [];
        if (inLocalStorege) {
            dmnList = inLocalStorege;
        }
        this.setState({ dmnList: dmnList, visible: true, event: event });
    }
    selectDmn = (name) => {
        let file = this.state.dmnList.find((el) => el.name === name);
        this.setState({ dmnFile: file })
    }

    createDmn = (value) => {
        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");
        const BusinessRuleTask = bpmnFactory.create("bpmn:BusinessRuleTask");
        let selection = this.props.modeler.get('selection');
        BusinessRuleTask.set('name', value.name);
        BusinessRuleTask.set('decisionRef', value.decisionRef);
        BusinessRuleTask.set('decisionRefBinding', 'deployment');
        BusinessRuleTask.set('resultVariable', value.resultVariable);
        BusinessRuleTask.set('mapDecisionResult', value.mapDecisionResult);
        if(value.documentation){
            const newDocumentation = bpmnFactory.create('bpmn:Documentation');
            newDocumentation.text = value.documentation;
            
            // Add the new documentation to the Business Rule Task
            BusinessRuleTask.get('documentation').push(newDocumentation);
        }
        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [],
            outputParameters: []
        });
        if (value.InputParameters) {
            value.InputParameters.forEach((el) => {
                let input = bpmnFactory.create('camunda:InputParameter', {
                    name: el.name,
                    value: el.value
                });
                inputOutput.inputParameters.push(input)
            })
        }
        if (value.OutputParameters) {
            value.OutputParameters.forEach((el) => {
                let output = bpmnFactory.create('camunda:OutputParameter', {
                    name: el.name,
                    value: el.value
                });
                inputOutput.outputParameters.push(output)
            })
        }
        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
        extensionElements.set("values", [inputOutput]);
        const businessRuleTask = elementFactory.createShape({
            type: "bpmn:BusinessRuleTask",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
            businessObject: BusinessRuleTask
        });
        businessRuleTask.businessObject.set("extensionElements", extensionElements);

        if (this.state.values) {
            let newElement = modeling.replaceShape(this.props.activeElement, businessRuleTask);
            selection.select(newElement);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [businessRuleTask], {
                hints: {
                    autoSelect: [businessRuleTask]
                }
            });
        }

        this.setState({
            visible: false, values: null
        });
    }

    render() {
        let filesDmn = [];
        let optionsDmn = [];
        let mapRes = [
            {
                value: "collectEntries",
                label: 'collectEntries (List<Object>)'
            },
            {
                value: 'resultList',
                label: 'resultList (List<Map<String,Object>>)'
            },
            {
                value: 'singleEntry',
                label: 'singleEntry (Typed Value)'
            },
            {
                value: 'singleResult',
                label: 'singleResult (Map<String,Object>)'
            },
        ];
        if (this.state.dmnList.length > 0) {
            this.state.dmnList.forEach((elemnt) => {
                filesDmn.push({
                    value: elemnt.name,
                    label: elemnt.name
                })
            })

        }
        if (this.state.dmnFile) {
            this.state.dmnFile.id.forEach((el) => {
                optionsDmn.push({
                    value: el.id,
                    label: <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                        {isUndefined(el.name) ? el.id : el.name}
                        {el.type === 'decisionTable' ? <Tag color="#2db7f5"><InsertRowBelowOutlined /></Tag> : <Tag color="#2db7f5" style={{ fontSize: '12px' }}>{'{ }'}</Tag>}
                    </span>
                });
            });
        }
        let modal = <Modal title={this.state.values ? "Edit Dmn Table" : "Import Dmn Table"} destroyOnClose={true} footer={false} open={this.state.visible} onCancel={this.handleCancel} >
            <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.createDmn} autoComplete="off" initialValues={this.state.values}>
                <Form.Item
                    name='name'
                    label='Task name'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                        name='documentation'
                        label='Documentation'
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                <Form.Item
                    name='file'
                    label='File dmn'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  dmn Id',
                        },
                    ]}
                >
                    <Select
                        style={{ width: '100%' }}
                        options={filesDmn}
                        onChange={this.selectDmn}>
                    </Select>
                </Form.Item>
                <Form.Item
                    name='decisionRef'
                    label='Decision reference'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  dmn Id',
                        },
                    ]}
                >
                    <Select
                        style={{ width: '100%' }}
                        options={optionsDmn}>
                    </Select>
                </Form.Item>
                <Form.Item
                    name='resultVariable'
                    label='Result variable'
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='mapDecisionResult'
                    label='Map decision result'
                >
                    <Select
                        style={{ width: '100%' }}
                        options={mapRes}>
                    </Select>
                </Form.Item>


                <h4>Inputs</h4>
                <Form.List name="InputParameters" >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        label="Name variable"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing name',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        label="Value"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing value',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add field
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <h4>Outputs</h4>
                <Form.List name="OutputParameters" >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        label="Name variable"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing name',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        label="Value"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing value',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add field
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
        return <>{modal}<Tooltip placement="rightTop" title="Import Dmn Table">
            <InsertRowBelowOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '22px', marginLeft: '3px' }} />
        </Tooltip>
        </>
    }
}

export default BusinessRuleTask