import { connect } from 'react-redux';
import {fetchObjectList} from "../../actions/objectList";
import EntryNewPresenter from '../../components/aphinitToolsPanel/EditEntry/EntryNew';



const mapStateToProps = (state, ownProps) => {
    return {
        entryTypesRequestResult: state.objectList
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onMount() {
            dispatch(fetchObjectList());
        },
    }
};

const EntryNew = connect(
    mapStateToProps,
    mapDispatchToProps
)(EntryNewPresenter);

export default EntryNew;
