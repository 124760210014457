import { createRoot } from 'react-dom/client';
import EntryDetailOverlays from './EntryDetailOverlays';

export const overlaysMain = (contextElement, overlays,entryTypeList) => {
    const overlayContainer = document.createElement('div');
    if (contextElement ) {
        let id = contextElement.id;
        let baseDetailVariableName = contextElement.businessObject.extensionElements.values[0].inputParameters.find((el)=>el.name ==="entryDetailMap_BaseDetailVariableName")
        let attributesVariableName = contextElement.businessObject.extensionElements.values[0].inputParameters.find((el)=>el.name ==="entryDetailMap_PropertiesVariableName")

        // Создайте корень React в DOM-элементе
        const root = createRoot(overlayContainer);

        // Отрендерите компонент React в корень
        root.render(<EntryDetailOverlays baseDetailVariableName={baseDetailVariableName} attributesVariableName={attributesVariableName} entryTypeList={entryTypeList} />);

        overlays.add(id, 'note', {
            position: {
                bottom: 18,
                right: -22,
            },
            // show: function (element) {
            //     // Показывать наложение только для элементов с типом 'bpmn:ServiceTask'
            //     return element.type === "bpmn:ServiceTask";
            // },
            html: overlayContainer,
        });
    }



}