import userIdsCollectionImg from "../../image/userIdsColectionImg.png";

let userIdsCollectionManual = <><h2>User IDs collection</h2>

    <p>The User IDs collection creates a variable of type (collection) that will contain all user identifiers selected when configuring the component via the user interface.
         Subsequently, you can work with this collection through Multi-instance which iterates over this collection like a For each loop.
For example, how often this component is used.</p>
<img src={userIdsCollectionImg}  width="350px" height="115px"  />
<p>All people selected during component creation will receive a task.</p>

    </>
export default userIdsCollectionManual;