import { createBaseOptions, fetchJson, METHOD_GET, METHOD_POST } from "../utils/SuperAgentFetch";
import { config } from "../config/Config";
import { notificationError, notificationSuccess, } from "../utils/NotificationsHelper";


export const fetchDeployAndStartProcess = async (name, diagram, attachments, requestBody, processName, callback) => {

    const form = new FormData();

    form.append('deployment-name', name);
    form.append('deployment-source', 'Camunda Modeler');
    form.append('enable-duplicate-filtering', 'true');

    const diagramName = diagram.name;
    const blob = new Blob([diagram.contents], { type: 'text/xml' });

    form.append(diagramName, blob, diagramName);
    if (attachments) {
        attachments.forEach((file, i) => { 
            let data  = file.data;
            if(file.name.includes("form")){
                data  = JSON.stringify(file.data);
            }
            form.append("attachment" + i, new Blob([data]), file.name);
        });
    }


    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_POST;

    options["body"] = form;

    try {
        let response = await fetchJson(`${config.url.API_URL}/bpmn/deployment/create`, options);
        let id = null
        if (response && response.deployedProcessDefinitions) {
            id = Object.keys(response.deployedProcessDefinitions)[0]
        }
        else {
            let optionsP = createBaseOptions();
            optionsP.method = METHOD_GET;
            let processes = await fetchJson(`${config.url.API_URL}/bpmn/process-definition/statistics`, optionsP);
            if (processes && processes._embedded && processes._embedded.processDefinitionStatisticsResultDtoes) {
                let procDef = processes._embedded.processDefinitionStatisticsResultDtoes.find((el) => processName === el.definition.name);
                id = procDef.id
            }
        }
        if (id) {
            let body = requestBody ? JSON.parse(requestBody) : {}
            let requestOptions = createBaseOptions();
            requestOptions.method = METHOD_POST;
            requestOptions["body"] = body;
            let res = fetchJson(`${config.url.API_URL}/bpmn/process-definition/${id}/start`, requestOptions);
            res.then(json => {
                notificationSuccess('Success', 'Process started');
                callback('success');
            }).catch(error => {
                if (error.response && error.response.response) {
                    callback(error.response.response);
                }
                else {
                    notificationError("Error", "You made a mistake, don't expand the process");
                    callback('error');
                }
            });
        }
    } catch (error) {
        if (error.response && error.response.response) {
            callback(error.response.response);
        }
        console.log(error);
    };
};

