import {createBaseOptions, fetchJson,  METHOD_PATCH} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";

export const fetchUpdateUserCustomizations = (settings,callback) => {
    
    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_PATCH;
    options.headers["Content-Type"] = "application/json";
    options["body"] = settings;

    let r = fetchJson(`${config.url.API_URL}/usercustomization/custom/item`, options) // /async
    r.then(json => {
        console.log(json);
       callback();
    }).catch(error => {
        console.log(error);
       callback();
    });
};