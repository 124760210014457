import {createBaseOptions, fetchJson, METHOD_POST} from "../utils/SuperAgentFetch";
import {config} from "../config/Config";
import { notificationError, notificationSuccess, notificationWarning } from "../utils/NotificationsHelper";


export const fetchDeployDiagram = (name,diagram,attachments,callback) => {
    const form = new FormData();
    
    form.append('deployment-name', name);
    form.append('deployment-source', 'Camunda Modeler');
    form.append('enable-duplicate-filtering', 'true');

    const diagramName = diagram.name;


    const blob = new Blob([diagram.contents] , { type: 'text/xml' });

    form.append(diagramName, blob, diagramName);
    if(attachments){
        attachments.forEach((file,i) => {
            let data  = file.data;
            if(file.name.includes("form")){
                data  = JSON.stringify(file.data);
            }
            form.append("attachment"+i, new Blob([data] ), file.name);
          });
    }
    

    /** @type RequestOptions */
    let options = createBaseOptions();
    options.method = METHOD_POST;
   
    options["body"] = form;
   

    let r = fetchJson(`${config.url.API_URL}/bpmn/deployment/create`, options);
    r.then(json => {
        notificationSuccess('Success','Process deployed successfully')
        callback('success')
    }).catch(error => {
        if(error.response && error.response.response){
            console.log("callback error");
            callback(error.response.response)
        }
        console.log(error);
    });
};

