import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { rootReducer } from './reducers';
import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import websocketMiddleware from './modules/websocket/middleware/middleware';
import Keycloak from 'keycloak-js';
import { config } from "./config/Config";
import { requestUserLogin, requestUserLogout, successfulLogin, successfulTokenUpdate } from "./actions/loggedUser";
import { wsConnect, wsRefreshToken } from "./modules/websocket/actions";
import {CookiesProvider} from "react-cookie";


const history = createBrowserHistory();
const loggerMiddleware = createLogger();
const middlewares = [
    thunkMiddleware,
    loggerMiddleware,
    routerMiddleware(history),
    // websocketMiddleware
];
const store = createStore(rootReducer(history), applyMiddleware(...middlewares));
const keycloak = new  Keycloak(config.keycloak);

keycloak.init({ onLoad: 'login-required', checkLoginIframe: false, checkLoginIframeInterval: 1, promiseType: 'native' }).then(authenticated => {
    if (keycloak.authenticated) {
        //sessionStorage.setItem('kctoken', keycloak.token);

        //Updating some value in store to re-render the component
        store.dispatch(successfulLogin(keycloak));
        setInterval(() => {
            keycloak.updateToken(10).then((refreshed) => {
                if (refreshed) {
                    store.dispatch(successfulTokenUpdate(keycloak));
                    store.dispatch(wsRefreshToken(keycloak.token));
                } else {
                    // token is still valid
                }
            }).catch(() => {
                store.dispatch(requestUserLogout());
                keycloak.logout();
            });
        }, 10000);
        store.dispatch(wsConnect(
            {
                brokerURL: config.url.WS_URL,
                connectHeaders: {
                    application: "Aphinit react app2"
                },
                debug: (str) => {
                    // console.log(str);
                },
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000
            },
            keycloak.token,
            ["/topic/editedEntries"]
        ));
    } else {
        store.dispatch(requestUserLogin());
        keycloak.login();
    }
}).catch((e) => {
    alert('failed to initialize keycloak' + e);
});




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookiesProvider>
    <Provider store={store}>
        {/* <ConnectedRouter history={history}> */}
            <App keycloak={keycloak} />
        {/* </ConnectedRouter> */}
    </Provider>
    </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
// reportWebVitals();
