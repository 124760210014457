import React, { Component } from "react";
import { Card, Button,Upload  } from 'antd';
import { FolderOpenOutlined, SettingFilled, ProfileOutlined, BoxPlotOutlined,  InsertRowBelowOutlined, DesktopOutlined } from '@ant-design/icons';
import ModalSavedFile from "./savedFiles/ModalSavedFile";
import ModalDiagramView from "./diagramView/ModalDiagramView";
import aphinit from "./image/aphinit.svg"
import logoAph from "./image/aphinitLogo.svg"


class HomePage extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	
	render = () => {
	
		let main = <div className="HomePageMain" >
			<div className="logo">
				<img src={aphinit} alt="Logo" width="35%" height="35%" />
				<div className="textLogo"><img src={logoAph} alt="LogoText" width="80%" height="80%" /> </div>
			</div>
			<div className="mainContainerBtn">
				<Card
					style={{ marginRight: '50px', backgroundColor: '#fafafa', borderRadius: '20px' }}
					className="containerBtn"
				>
					<div style={{ fontSize: '32px', justifyContent: 'center', marginBottom: '50px', marginLeft: '20px' }}><SettingFilled style={{ color: '#8c8c8c' }} /> <span style={{ color: '#223366' }}>Create new</span> </div>
					<Button type="primary" block size='large' style={{ backgroundColor: '#223366', marginBottom: '30px' }} onClick={() => this.props.createNew('bpmn')} ><BoxPlotOutlined />BPMN diagram</Button>
					<Button type="primary" block size='large' style={{backgroundColor: '#223366', marginBottom: '30px' }} onClick={() => this.props.createNew('dmn')} ><InsertRowBelowOutlined />DMN diagram</Button>
					<Button type="primary" block size='large'style={{backgroundColor: '#223366' }} onClick={() => this.props.createNew('form')}><ProfileOutlined /> Form</Button>
				</Card>
				<Card
					style={{ backgroundColor: '#fafafa', borderRadius: '20px' }}
					className="containerBtn"
				>
					<div style={{ fontSize: '32px', justifyContent: 'center', marginBottom: '50px', marginLeft: '20px' }}><DesktopOutlined style={{ color: '#8c8c8c' }} /> <span style={{ color: '#223366' }}>Open </span> </div>
					<Upload
						beforeUpload={this.props.beforeUpload}
                        maxCount={1}
                        showUploadList={false}
                        onChange={this.props.handleOpenFile}
                    >
                      <Button type="primary" block size='large' style={{backgroundColor: '#223366' , marginBottom: '30px',width:'240px' }} > <FolderOpenOutlined /> Open file</Button>
                    </Upload>
					
					<div style={{ marginBottom: '30px' }}><ModalDiagramView type={'primary'} openDeployedProcess={this.props.setSelectedFile} /></div>

					<ModalSavedFile type={'primary'} createNew={this.props.createNew}  />
				</Card>
			</div>

		</div>;
		
		return (
			main
		);
	};
}
export default HomePage;
