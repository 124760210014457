import multipleInstanceImg from "../image/multipleInstance.png";
import loopImg from "../image/loop.png";

let multipleInstance = <><h1>Multiple Instance</h1>

    <p>A multi-instance activity is a way of defining repetition for a certain step in a business process. In programming concepts, a multi-instance matches the 
    <strong> For each</strong> construct: it allows execution of a certain step or even a complete subprocess for each item in a given collection, sequentially or in parallel.
    </p>

    <p>A multi-instance is a regular activity that has extra properties defined (so-called <code>multi-instance characteristics</code>)
        which will cause the activity to be executed multiple times at runtime. Following activities can become multi-instance activities:
    </p>
    <ul>
        <li>Service Task</li>
        <li>Send Task</li>
        <li>User Task</li>
        <li>Business Rule Task</li>
        <li>Script Task</li>
        <li>Receive Task</li>
        <li>Manual Task</li>
        <li>(Embedded) Sub-Process</li>
        <li>Call Activity</li>
        <li>Transaction Subprocess</li>
    </ul>

    <p>A Gateway or Event can not become multi-instance.</p>

    <p>If an activity is multi-instance, this is indicated by three short lines at the bottom of the activity.
        Three vertical lines indicate that the instances will be executed in <strong>parallel</strong>,
        while three horizontal lines indicate <strong>sequential</strong> execution.
    </p>

    <img src={multipleInstanceImg}  width="250px" height="90px"  />
    <h1 >Loops</h1>
    <p>The loop marker is not natively supported yet by the engine. 
        For Multiple Instance, the number of repetitions is known in advance - which makes it a bad candidate for loops anyway, as it defines a completion condition that may already be sufficient in some cases.</p>
        <img src={loopImg}  width="350px" height="121px"  />
</>
export default multipleInstance;