import React, { Component } from "react";
import { Input, Modal, Select, Space, Button, Form, Tooltip } from 'antd';
import { ApiOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import TestConnector from "./TestConnector";

class Connector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            method: null,
            event: null,
            values: null,
            testValue: null,
        };

    }
    formRef = React.createRef();
    componentDidUpdate(prevProps, prevState) {
        let activeElement = this.props.activeElement
        if (activeElement && activeElement.type === 'bpmn:ServiceTask' && !this.state.visible && activeElement.businessObject.extensionElements && activeElement.businessObject.extensionElements.values[0].connectorId === 'http-connector') {
            let value = {};
            let inputOutput = activeElement.businessObject.extensionElements.values[0].inputOutput;
            value.inputs = []
            let param = ["Content-Type", "body", "method", "url"]
            inputOutput.inputParameters.forEach((el) => {
                if (param.find((par) => par === el.name)) {
                    value[el.name] = el.value
                }
                else {
                    value.inputs.push(el)
                }
            });
            value.name = activeElement.businessObject.name;
            value.response = inputOutput.outputParameters[0].name;
            this.setState({
                values: value,
                visible: true
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false, values: null });
        this.props.clearElement();
    }
    showModal = (event) => {
        this.setState({
            visible: true,
            event: event
        });
    }
    method = (value) => {
        this.setState({
            method: value
        })
    }

    createConnector = (value) => {
        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");
        let selection = this.props.modeler.get('selection');

        const serviceTask = elementFactory.createShape({
            type: "bpmn:ServiceTask",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
        });

        let urlInputParameter = bpmnFactory.create('camunda:InputParameter', {
            name: 'url',
            value: value.url
        });
        let methodInputParameter = bpmnFactory.create('camunda:InputParameter', {
            name: 'method',
            value: value.method
        });
        let contentTypeInputParameter = bpmnFactory.create('camunda:InputParameter', {
            name: 'Content-Type',
            value: value.contentType
        });
        let bodyInputParameter = bpmnFactory.create('camunda:InputParameter', {
            name: 'body',
            value: value.body
        });


        let responseOutPutParameter = bpmnFactory.create('camunda:OutputParameter', {
            name: value.response,
            definition: bpmnFactory.create('camunda:Script', {
                scriptFormat: 'javascript',
                value: 'S(response)'
            })
        });

        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [urlInputParameter, methodInputParameter, contentTypeInputParameter, bodyInputParameter],
            outputParameters: [responseOutPutParameter]
        });

        if (value.inputs) {
            value.inputs.forEach((el) => {
                let input = bpmnFactory.create('camunda:InputParameter', {
                    name: el.name,
                    value: el.value
                });
                inputOutput.inputParameters.push(input)
            })
        }
        if (value.outputs) {
            value.outputs.forEach((el) => {
                let output = bpmnFactory.create('camunda:OutputParameter', {
                    name: el.name,
                    value: el.value
                });
                inputOutput.outputParameters.push(output)
            })
        }

        const connector = bpmnFactory.create("camunda:Connector", {
            connectorId: "http-connector", inputOutput
        });
        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");

        serviceTask.businessObject.set('name', value.name);

        extensionElements.set("values", [connector]);
        serviceTask.businessObject.set("extensionElements", extensionElements);

        if (this.state.values) {
            let newElement = modeling.replaceShape(this.props.activeElement, serviceTask);
            selection.select(newElement);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [serviceTask], {
                hints: {
                    autoSelect: [serviceTask]
                }
            });
        }
        this.setState({
            visible: false, values: null,
        });

    }
    test = () => {
        let values = this.formRef.current.getFieldsValue();
        if (values) {
            if (values.url && values.method && values.contentType) {
                this.setState({ testValue: values })
            }
        }
    }
    validateJSON = (rule, value) => {
        if (!value) {
            return Promise.resolve();
        }

        try {
            const sanitizedValue = value.replace(/\\n/g, '\n');
            JSON.parse(sanitizedValue);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(new Error('Invalid JSON'));
        }
    };

    render() {
        let optionsMethod = [
            {
                value: 'GET',
                label: 'GET'
            },
            {
                value: 'POST',
                label: 'POST'
            },
            {
                value: 'UPDATE',
                label: 'UPDATE'
            },
            {
                value: 'DELETE',
                label: 'DELETE'
            },
        ];

        let modal = <Modal title="Create Connector" destroyOnClose={true} open={this.state.visible} footer={false} onCancel={this.handleCancel} >
            <Form name="dynamic_form_nest_item" ref={this.formRef} layout="vertical" onFinish={this.createConnector} autoComplete="off" initialValues={this.state.values}>
                <Form.Item
                    name='name'
                    label='Name'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='url'
                    label='URL'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  url',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='method'
                    label='Method'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  method',
                        },
                    ]}
                >
                    <Select
                        onChange={this.method}
                        style={{ width: '100%' }}
                        options={optionsMethod}>
                    </Select>
                </Form.Item>
                <Form.Item
                    name='body'
                    label='Body'
                    initialValue={this.state.values ? this.state.values.body : null}
                    rules={[{
                        validator: this.validateJSON
                    }
                    ]}
                >
                    <Input.TextArea size="large" />
                </Form.Item>
                <Form.Item
                    name='response'
                    label='Response variable name'
                    rules={[
                        {
                            required: true,
                            message: 'Missing  response variable name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name='contentType'
                    label='Content-Type'
                    initialValue={'application/json'}
                >
                    <Input />
                </Form.Item>


                <h4>Headers</h4>
                <Form.List name="inputs" >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'name']}
                                        label="Name variable"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing first name',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'value']}
                                        label="Value"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Missing last value',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                    Add field
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Button type="default" onClick={this.test} style={{ marginRight: '10px' }} >
                        Test
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
        return <>{modal}<TestConnector values={this.state.testValue} /><Tooltip placement="rightTop" title="Connector">
            <ApiOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '22px', marginLeft: '3px' }} />
        </Tooltip></>

    }
}

export default Connector