import MainPanelPresenter from "../components/aphinitToolsPanel/MainPanel";
import { connect } from "react-redux";
import { fetchUserList } from "../actions/userList";
import { fetchGroupList } from "../actions/groupList";
import { fetchUserCustomizations } from "../actions/userCustomizations";
import { isUndefined } from '../utils/JsObjectHelper';

const getSetup = (customizationsList) => {
    if (!isUndefined(customizationsList) && customizationsList.getState().isDone()) {
        let setList = customizationsList.getData().find(el => el.type === "modelerSetup");
		if(isUndefined(setList)){
			setList = null
		}
        return setList;
    } else {
        return null;
    }
};

const mapStateToProps = (state, ownProps) => {
	return {
		mySetup: getSetup(state.userCustomizations),
		userListRequestResult: state.userList,
		groupListRequestResult: state.groupList,
		user: state.loggedUser.user,
	}
};

const mapDispatchToProps = (dispatch, ownProps) => {
	return {
		onMount: () => {
			dispatch(fetchGroupList());
			dispatch(fetchUserList());
			dispatch(fetchUserCustomizations());
		},
		
	};
};

const MainPanel = connect(
	mapStateToProps,
	mapDispatchToProps
)(MainPanelPresenter);

export default MainPanel;
