const docker = {
    url: {
        API_URL: window._env_.APHINIT_M_API_URL,
        BPMN_URL: window._env_.APHINIT_M_BPMN_URL
    },
    keycloak: {
        realm: window._env_.APHINIT_M_KEYCLOAK_REALM,
        url: window._env_.APHINIT_M_KEYCLOAK_URL,
        clientId: window._env_.APHINIT_M_KEYCLOAK_CLIENTID
    }
};

/*const dev = {
    url: {
        API_URL: "https://dev-be.aphinit.com/api",
        BPMN_URL: "https://dev-bpmn.aphinit.com"
    },
    keycloak: {
        realm: "dev",
        url: "https://dev-keycloak.aphinit.com/auth",
        clientId: "dev-web-aphinit"
    }
};*/

//Jakub test
export const config = docker; //process.env.NODE_ENV === "development" ? dev : prod;