import React, { Component } from "react";
import { Button, Input, Popover, Table, Tabs, Alert, Tooltip, Upload } from 'antd';
import { RocketFilled, DeleteOutlined, FolderOpenOutlined, PlusOutlined, CloseOutlined, UserOutlined, RobotOutlined } from '@ant-design/icons';
import { is } from "bpmn-js/lib/util/ModelUtil";
import cloneDeep from "lodash.clonedeep";
import { fetchDeployDiagram } from "../apicalls/DeployDiagram";
import MyFormViewer from "./savedFiles/MyFormViewer";
import { generateID } from "../utils/generateID";
import MyDiagramViewer from "./diagramView/MyDiagramViewer";
import { isUndefined } from "../utils/JsObjectHelper";
import { notificationError } from "../utils/NotificationsHelper";

class Deploy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            req: null,
            alert: false,
            popover: false,
            formListMenu: false,
            savedForms: [],
            selectedFiles: [],
            nameUploadedFile: null,
            nameDeployment: null,
            savedDmn: [],
            savedBpmn: [],
        };
    }

    handleVisibleChange = (popover) => {
        if (popover) {
            this.props.getDiagram('deploy');
            let selectedFiles = [];
            let alert = false;
            let dmnList = localStorage.getItem('savedDmn') ? JSON.parse(localStorage.getItem('savedDmn')) : [];
            let formList = localStorage.getItem('savedForm') ? JSON.parse(localStorage.getItem('savedForm')) : [];
            let bpmnList = localStorage.getItem('savedBpmn') ? JSON.parse(localStorage.getItem('savedBpmn')) : [];
            let name = this.props.diagram.name.includes('.bpmn');
            name = name ? this.props.diagram.name.slice(0, -5) : this.props.diagram.name;

            let elementRegistry = this.props.modeler.get('elementRegistry');
            let form = elementRegistry.filter(function (element) {
                return is(element, "bpmn:UserTask");
            });
            let dmn = elementRegistry.filter(function (element) {
                return is(element, "bpmn:BusinessRuleTask");
            });
            let CallActivity = elementRegistry.filter(function (element) {
                return is(element, "bpmn:CallActivity");
            });
            let startEvent = elementRegistry.filter(function (element) {
                return is(element, "bpmn:StartEvent");
            });
            if(startEvent){
                startEvent.forEach((el) => {
                    let useForm = formList.find((element) => element.id === el.businessObject.formRef);
                    if (useForm) {
                        useForm.added = 'pc';
                        let exists = selectedFiles.find((el) => el === useForm);
                        if (!exists) {
                            selectedFiles.push(useForm)
                        }
                    }
                })
            }
            if(CallActivity){
                CallActivity.forEach((bpmn)=>{
                    let useBpmn = bpmnList.find((element) => element.id === bpmn.businessObject.calledElement);
                    if(useBpmn){
                        useBpmn.added = 'pc';
                        let exists = selectedFiles.find((el) => el === useBpmn);
                        if (!exists) {
                            selectedFiles.push(useBpmn)
                        }
                    }
                })
            }
            if (form) {
                form.forEach((el) => {
                    let useForm = formList.find((element) => element.id === el.businessObject.formRef);
                    if (useForm) {
                        useForm.added = 'pc';
                        let exists = selectedFiles.find((el) => el === useForm);
                        if (!exists) {
                            selectedFiles.push(useForm)
                        }
                    }
                })
            }
            if (dmn) {
                dmn.forEach((el) => {
                    let useDmn = dmnList.find((element) => element.id.find((dec) => dec.id === el.businessObject.decisionRef));
                    if (useDmn) {
                        useDmn.added = 'pc';
                        let exists = selectedFiles.find((el) => el === useDmn);
                        if (!exists) {
                            selectedFiles.push(useDmn)
                        }
                    }
                })
            }
            if (selectedFiles.length > 0) {
                alert = true
            }
            else if (selectedFiles.length === 0) {
                selectedFiles = this.state.selectedFiles
            }
            this.setState({
                popover,
                alert: alert,
                nameDeployment: name,
                savedBpmn: bpmnList,
                savedForms: formList,
                savedDmn: dmnList,
                selectedFiles: selectedFiles,
            })
        }
        else {
            this.setState({ popover })
        }
    }
    beforeUpload = (file) => {
		const isFile = file.name.includes(".bpmn") || file.name.includes(".form") || file.name.includes(".dmn");

		return !isFile;
	};
	handleOpenFile = (info) => {
		const reader = new FileReader();
		if (info.file.name.includes("bpmn")) {
			reader.onloadend = this.selectedFile
			reader.readAsText(info.fileList[0].originFileObj);
		}
		else if (info.file.name.includes('.form')) {
			reader.onloadend = this.selectedFile
			reader.readAsText(info.fileList[0].originFileObj);
		}
		else if (info.file.name.includes('.dmn')) {
			reader.onloadend = this.selectedFile
			reader.readAsText(info.fileList[0].originFileObj);
		}
		this.setState({ nameUploadedFile: info.file.name });
	};
    selectedFile = (form) => {
        let dublicate = cloneDeep(this.state.selectedFiles);
        let fileName = form.name ? form.name : this.state.nameUploadedFile;
        let inList = this.state.selectedFiles.find((el) => el.name === fileName);
        form.added = 'man';
        if (!inList) {
            if (!form.name) {
                dublicate.push({ name: this.state.nameUploadedFile, data: form.target.result, added: 'man' })
            }
            else {
                dublicate.push(form)
            }
            this.setState({ selectedFiles: dublicate })
        }

    }
    closePopoverFormList = () => {
        this.setState({ formListMenu: false })
    }
    showFormList = (formListMenu) => {
        this.setState({ formListMenu })
    }
    deleteForm = (form) => {
        let clone = cloneDeep(this.state.selectedFiles)
        let index = clone.findIndex((el) => el.name === form.name)
        clone.splice(index, 1)
        this.setState({ selectedFiles: clone })
    }
    nameDeployment = (name) => {
        this.setState({
            nameDeployment: name.target.value,
            req: null
        })
    }
    deploy = () => {
        let elementRegistry = this.props.modeler.get('elementRegistry');
        let process = elementRegistry.filter(function (element) {
            return is(element, 'bpmn:Process');
        });
        if (process.length === 1 && isUndefined(process[0].businessObject.name)) {
            notificationError("Process name is missing", "Please name the process to make it work properly");
        }
        else if (process.length === 0) {
            let participants = elementRegistry.filter((element) => is(element, "bpmn:Participant"));
            if (isUndefined(participants[0].businessObject.processRef.name)) {
                notificationError(participants[0].businessObject.processRef.id + " name is missing", "Please name the process to make it work properly");
            }
        }
        else if (this.state.nameDeployment) {
            fetchDeployDiagram(this.state.nameDeployment, this.props.diagram, this.state.selectedFiles, (res) => res === 'success' ? this.setState({ popover: false }) : (this.setState({ popover: false }), this.props.errorsLog(res)))
        }
        else {
            this.setState({ req: 'error' })
        }
    }

    render = () => {
        const sharedProps = {
            style: {
                width: '100%',
            },
            defaultValue: this.state.nameDeployment,
        };
        let columnsDmn = [
            {
                dataSource: "name",
                key: "name",
                width: "60%",
                render: (text, record) => {
                    return (
                        <span >{text.name}</span>
                    )
                },
            },
            {
                dataSource: "name",
                key: "name",
                width: "25%",
                render: (text, record) => {
                    return (
                        <Button type="link" onClick={() => this.selectedFile(text)}><PlusOutlined style={{ fontSize: '14px' }} /> </Button>
                    )
                },
            },
        ]
        let columns = [
            {
                dataSource: "name",
                key: "name",
                width: "60%",
                render: (text, record) => {
                    return (
                        <Popover
                            content={<MyFormViewer data={text.data} formId={"form" + generateID()} />}
                            title={text.name}
                            trigger="hover"
                            placement="right"
                            style={{ marginLeft: '200px' }}
                        >
                            <span >{text.name}</span>
                        </Popover>
                    )
                },
            },
            {
                dataSource: "name",
                key: "name",
                width: "25%",
                render: (text, record) => {
                    return (
                        <Button type="link" onClick={() => this.selectedFile(text)}><PlusOutlined style={{ fontSize: '14px' }} /> </Button>
                    )
                },
            },
        ];
        let columnsBpmn = [
            {
                dataSource: "name",
                key: "name",
                width: "60%",
                render: (text, record) => {
                    return (
                        <Popover
                            content={<MyDiagramViewer data={text.data} />}
                            title={text.name}
                            trigger="hover"
                            placement="right"
                            style={{ marginLeft: '200px' }}
                        >
                            <span >{text.name}</span>
                        </Popover>
                    )
                },
            },
            {
                dataSource: "name",
                key: "name",
                width: "25%",
                render: (text, record) => {
                    return (
                        <Button type="link" onClick={() => this.selectedFile(text)}><PlusOutlined style={{ fontSize: '14px' }} /> </Button>
                    )
                },
            },
        ]

        let columns2 = [
            {
                dataSource: "name",
                key: "name",
                render: (text, record) => {
                    return (
                        <div className="tblDeployFiles" ><span >{text.name}</span></div>
                    )
                },
            },
            {
                dataSource: "name",
                key: "added",
                render: (text, record) => {
                    return text.added === 'man' ? <UserOutlined /> : <RobotOutlined />
                },
            },
            {
                dataSource: "name",
                key: "name",
                render: (text, record) => {
                    return (
                        <Button type="link" onClick={() => this.deleteForm(text)}><DeleteOutlined style={{ fontSize: '14px', color: 'red' }} /> </Button>
                    )
                },
            },
        ]

        let items = [
            {
                label: "BPMN",
                children: <Table
                    showHeader={false}
                    pagination={false}
                    columns={columnsBpmn}
                    dataSource={this.state.savedBpmn}
                    size='small'
                    rowKey="name"
                    style={{ maxWidth: '250px' }}
                    scroll={{
                        y: 350,
                    }} />,
                key: 1
            },
            {
                label: "Form",
                children: <Table
                    showHeader={false}
                    pagination={false}
                    columns={columns}
                    dataSource={this.state.savedForms}
                    size='small'
                    rowKey="name"
                    style={{ maxWidth: '250px' }}
                    scroll={{
                        y: 250,
                        x: 'hiden'
                    }}
                />,
                key: 2
            },
            {
                label: "DMN",
                children: <Table
                    showHeader={false}
                    pagination={false}
                    columns={columnsDmn}
                    dataSource={this.state.savedDmn}
                    size='small'
                    rowKey="name"
                    style={{ maxWidth: '250px' }}
                    scroll={{
                        y: 350,
                    }} />,
                key: 3
            }
        ]
        let alert = null;
        if (this.state.alert) {
            alert = <Alert
                message="Additional files held in 'My saved files' were added automatically. Files held elsewhere heeds to be added manually in order to make process work correctly."
                type="info"
                closable
            />
        }


        let content = (
            <div style={{ width: "290px" }}>
                {alert}
                <span style={{ fontSize: '16px' }}>Deployment name</span>
                <Input onChange={this.nameDeployment} status={this.state.req}{...sharedProps} placeholder="Enter deployment name"></Input>
                <div style={{ marginTop: '10px',display:'flex',justifyItems:'row' }}>
                    <span style={{ float: 'left', fontSize: '16px', marginRight: '70px' }}>Include additional files:</span>
                    <Popover
                        content={<Tabs tabPosition="left" type="line" items={items} />}
                        title={<>"Saved files" <Button type="link" className="btnPopover" onClick={this.closePopoverFormList} ><CloseOutlined style={{ fontSize: '14px', color: 'black', marginLeft: '230px' }} /> </Button></>}
                        trigger="click"
                        placement="rightBottom"
                        open={this.state.formListMenu}
                        onOpenChange={this.showFormList}
                    >
                        <Button type="link" className="btnPopover"><PlusOutlined style={{ fontSize: '16px', marginRight: '5px' }} /> </Button>
                    </Popover>
                    <Upload
                        beforeUpload={this.beforeUpload}
                        maxCount={1}
                        showUploadList={false}
                        onChange={this.handleOpenFile}
                    >
                        <Button type="link" style={{ color: 'black', padding: '0' }} ><FolderOpenOutlined style={{ color: '#1677ff', fontSize: '16px' }} /></Button>
                    </Upload>
                </div>
                {this.state.selectedFiles.length > 0 ?
                    <Table
                        showHeader={false}
                        pagination={false}
                        columns={columns2}
                        dataSource={this.state.selectedFiles}
                        rowKey="name"
                        size="small"
                        style={{ maxWidth: '280px' }}
                    /> : null}
                <Button type="primary" onClick={this.deploy} style={{ width: '100%' }} >Deploy</Button>
            </div>);
        let main = (
            <Popover
                content={content}
                title="Deploy diagram"
                trigger="click"
                open={this.state.popover}
                onOpenChange={this.handleVisibleChange}
            >
                <Tooltip placement="top" title="Deploy process">
                    <Button type="link"><RocketFilled style={{ color: '#595959', fontSize: '18px' }} /> </Button>
                </Tooltip>
            </Popover>
        );

        return (
            <>
                {main}
            </>
        );
    };
}

export default Deploy;
