import React, { Component } from "react";
import { Input, Modal, Select, Button, Form, Tooltip } from 'antd';
import { AuditOutlined, } from '@ant-design/icons';
import { isArray, } from "../../utils/JsObjectHelper";

class ChangeObjectRights extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            method: null,
            event: null,
            values: null
        };
    }
    componentDidUpdate(prevProps, prevState) {
        let activeElement = this.props.activeElement
        if (activeElement && activeElement.type === 'bpmn:ServiceTask' && !this.state.visible && activeElement.businessObject.delegateExpression === "${changeRightsDelegate}") {
            let value = {};
            let inputParameters = null;
            if (this.props.activeElement.businessObject.extensionElements) {
                inputParameters = this.props.activeElement.businessObject.extensionElements.values[0].inputParameters;
            }
            value.name = activeElement.businessObject.name;
            inputParameters.forEach((el) => value[el.name] = el.value);
            value.editGroupIds = value.editGroupIds ? value.editGroupIds.split(",") : undefined;
            value.viewGroupIds = value.viewGroupIds ? value.viewGroupIds.split(",") : undefined;
            value.noneGroupIds = value.noneGroupIds ? value.noneGroupIds.split(",") : undefined;
            this.setState({
                values: value,
                visible: true
            })
        }
    }
    handleCancel = () => {
        this.setState({ visible: false, values: null });
        this.props.clearElement()
    }
    showModal = (event) => {
        this.setState({
            visible: true,
            event: event
        });
    }
    method = (value) => {
        this.setState({
            method: value
        })
    }

    createConnector = (value) => {
        let viewGroupIds = value.viewGroupIds ?isArray(value.viewGroupIds )? value.viewGroupIds.join():value.viewGroupIds  : null;
        let editGroupIds = value.editGroupIds ?isArray(value.editGroupIds )? value.editGroupIds.join():value.editGroupIds : null;
        let noneGroupIds = value.noneGroupIds ?isArray(value.editGroupIds )? value.noneGroupIds.join():value.editGroupIds : null;
        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");
        let selection = this.props.modeler.get('selection');

        let ServiceTask = bpmnFactory.create('bpmn:ServiceTask');
        ServiceTask.definitionRef = 'modelerTemplate';
        ServiceTask.modelerTemplate = 'com.aphinit.bpmn.ChangeRight';
        ServiceTask.delegateExpression = '${changeRightsDelegate}';

        let editGroup = bpmnFactory.create('camunda:InputParameter', {
            name: "editGroupIds",
            value: editGroupIds,
        });
        let viewGroup = bpmnFactory.create('camunda:InputParameter', {
            name: "viewGroupIds",
            value: viewGroupIds
        });
        let noneGroup = bpmnFactory.create('camunda:InputParameter', {
            name: "noneGroupIds",
            value: noneGroupIds
        });
        let removeAllExistingGroup = bpmnFactory.create('camunda:InputParameter', {
            name: "removeAllExistingGroupIds",
            value: value.removeAllExistingGroupIds
        });

        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [editGroup, viewGroup, noneGroup, removeAllExistingGroup],
            outputParameters: []
        });
        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
        extensionElements.set("values", [inputOutput]);
        const serviceTask = elementFactory.createShape({
            type: "bpmn:ServiceTask",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
            businessObject: ServiceTask
        });
        serviceTask.businessObject.set('name', value.name);
        serviceTask.businessObject.set("extensionElements", extensionElements);
        if (this.state.values) {
           let newElement= modeling.replaceShape(this.props.activeElement, serviceTask);
            selection.select(newElement);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [serviceTask], {
                hints: {
                    autoSelect: [serviceTask]
                }
            });
        }
        this.setState({
            visible: false,values:null,
        });
    };


    render() {
        let optionsRemove = [{
            value: '${true}',
            label: 'True'
        },
        {
            value: '${false}',
            label: 'False'
        }];
        let options = [];
        if (isArray(this.props.groupList)) {
            this.props.groupList.forEach((el) => {
                options.push({
                    value: el.id,
                    label: el.name
                })
            })
        }
        let modal = <Modal title="Create Change Object Rights" destroyOnClose={true} open={this.state.visible} footer={false} onCancel={this.handleCancel} >
            <Form name="dynamic_form_nest_item" layout="vertical" onFinish={this.createConnector} autoComplete="off" initialValues={this.state.values}>
                <Form.Item
                    name='name'
                    label='Task name'
                    initialValue={'Change Object Rights'}
                    rules={[
                        {
                            required: true,
                            message: 'Missing  name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='editGroupIds'
                    label='Edit group IDs'
                >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        options={options}>
                    </Select>
                </Form.Item>
                <Form.Item
                    name='viewGroupIds'
                    label='View group IDs'
                >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        options={options}>
                    </Select>
                </Form.Item>
                <Form.Item
                    name='noneGroupIds'
                    label='None right group IDs'
                >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        options={options}>
                    </Select>
                </Form.Item>
                <Form.Item
                    initialValue={this.state.values?this.state.values.removeAllExistingGroupIds :'${true}'}
                    name='removeAllExistingGroupIds'
                    label='Remove all existing rights'
                >
                    <Select
                        style={{ width: '100%' }}
                        options={optionsRemove}>
                    </Select>
                </Form.Item>



                <div style={{ display: 'flex', justifyContent: 'end', }}>
                    <Button type="default" onClick={this.handleCancel} style={{ marginRight: '10px' }} >
                        Cancel
                    </Button>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Modal>
        return <>{modal}<Tooltip placement="leftTop" title="Change Object Rights">
            <AuditOutlined onClick={(event) => this.showModal(event)} style={{ fontSize: '22px', marginLeft: '3px' }} />
        </Tooltip>
        </>
    }
}
export default ChangeObjectRights