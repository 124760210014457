import React from 'react';
import MainContext from '../../utils/Context'

class NotificationComponent extends React.Component {
  static contextType = MainContext;

  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      hasShownMessage: false,
    };

    this.itemsRef = React.createRef();
    this.setupRef = React.createRef();
  }

  componentDidMount() {
    this.itemsRef.current = this.props.items;
    this.setupRef.current = this.props.mySetup;

    const time = this.props.mySetup ? this.props.mySetup.value.notification * 60 * 1000 : 600000;

    const showNotification = () => {
      const hasUnsavedWork = this.itemsRef.current.find((item) => item.label.props.children[1].props && item.label.props.children[1].props.children && item.label.props.children[1].props.children === "*" ? true : undefined);
      console.log("Show notification");
      const now = new Date();
      console.log(now);
      console.log(this.props.mySetup);

      if (this.props.mySetup && this.props.mySetup.value.tips === false) {
        console.log("if 1");
        const timerId = setTimeout(showNotification, time);
        this.setState({ timer: timerId, hasShownMessage: false });
      } else {
        console.log("else");
        if (hasUnsavedWork && !this.context.visibleMessageCat && !this.state.hasShownMessage) {
          console.log("else if 1");
          this.context.showMessageCat(true);
         /// this.setState({ hasShownMessage: false });
          const timerId = setTimeout(showNotification, time);
          this.setState({ timer: timerId });
        } else {
          console.log("else if 2");
          const timerId = setTimeout(showNotification, time);
          this.setState({ timer: timerId, hasShownMessage: false });
        }
      }
    };

    if (this.state.timer === null) {
      const timerId = setTimeout(showNotification, time);
      this.setState({ timer: timerId });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.itemsRef.current = this.props.items;
    }
    if (prevProps.mySetup !== this.props.mySetup) {
      this.setupRef.current = this.props.mySetup;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.timer);
    this.setState({ timer: null });
  }

  render() {
    return null;
  }
};

export default NotificationComponent;

// import { useState, useEffect, useContext, useRef } from 'react';
// import MainContext from '../../utils/Context'

// const NotificationComponent = (props) => {
//   const [timer, setTimer] = useState(null);
//   const [hasShownMessage, setHasShownMessage] = useState(false);

//   const context = useContext(MainContext);
//   const setupRef = useRef(props.mySetup);
//   const itemsRef = useRef(props.items);
//   useEffect(() => {
//     itemsRef.current = props.items;
//   }, [props.items]);
//   useEffect(() => {
//     setupRef.current = props.mySetup;
//   }, [props.mySetup]);

//   useEffect(() => {
//     let time = props.mySetup ? props.mySetup.value.notification * 60 * 1000 : 600000;
//     const showNotification = () => {
//       const hasUnsavedWork = itemsRef.current.find((item) => item.label.props.children[1].props && item.label.props.children[1].props.children && item.label.props.children[1].props.children === "*" ? true : undefined);//
//       console.log("Show notification");
//       console.log(props.mySetup);
//       if (props.mySetup && props.mySetup.value.tips === false) {
//         const timerId = setTimeout(showNotification, time);
//         setTimer(timerId);
//         setHasShownMessage(false);
//       }
//       else {
//         if (hasUnsavedWork && !context.visibleMessageCat && !hasShownMessage) {
//           context.showMessageCat(true);
//           setHasShownMessage(true);
//           const timerId = setTimeout(showNotification, time);
//           setTimer(timerId);
//         }
//         else {
//           const timerId = setTimeout(showNotification, time);
//           setTimer(timerId);
//           setHasShownMessage(false);
//         }
//       }
//     };

//     if (timer === null) {
//       const timerId = setTimeout(showNotification, time);
//       setTimer(timerId);
//     }

//     return () => {
//       clearTimeout(timer);
//       setTimer(null);
//     };
//   }, [props.mySetup]);

// };

// export default NotificationComponent;


