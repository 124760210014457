import React, { Component } from "react";
import { Modal, Tooltip, Select, Form, Button, Input } from 'antd';
import { FileTextOutlined, } from '@ant-design/icons';
import { isEmptyObject, isUndefined } from "../../../utils/JsObjectHelper";
import AttributeForm from "./AttributeForm";
import entryNewImg from "../../custom/customIcon/entryNew/entryNew.png"
const { TextArea } = Input;


class EntryNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            method: null,
            event: null,
            values: null,
            entryType: null,
            entryTypesList: null,
            parentId: null,
            description: '', 
            descriptionError: false, 
        };
    }
    componentDidMount() {
        this.props.onMount();
        if (!isUndefined(this.props.entryTypesRequestResult) && this.props.entryTypesRequestResult.getState().isDone()) {
            let entryTypesList = this.props.entryTypesRequestResult.getData();
            this.setState({ entryTypesList: entryTypesList })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        let activeElement = this.props.activeElement;
        if (activeElement && activeElement.type === 'bpmn:ServiceTask' && !this.state.visible && activeElement.businessObject.delegateExpression === "${entryNew}") {
            let value = {};
            let inputParameters = null;
            let entryType = null;
            let parentId = null;
            if (this.props.activeElement.businessObject.extensionElements) {
                inputParameters = this.props.activeElement.businessObject.extensionElements.values[0].inputParameters;
                entryType = this.props.activeElement.businessObject.extensionElements.values[1].value;
                parentId = this.props.activeElement.businessObject.extensionElements.values[2].value;
            }

            let arr = Object.entries(this.props.activeElement.businessObject);
            console.log(arr);
            arr.forEach((el) => {
                if (el[0] === 'name') {
                    value.taskName = el[1];
                }
            });
            entryType = this.state.entryTypesList.find((el) => entryType === el.type);
            console.log(inputParameters);
            inputParameters.forEach((el) => {
                if (el.name === "entryNew_Json") {
                    let properties = JSON.parse(el.value).properties;
                    for (let key in properties) {
                        value[key] = properties[key];
                    }
                } else {
                    value[el.name] = el.value;
                }
            });
            console.log(value);
            this.setState({
                parentId: parentId,
                entryType: entryType,
                values: value,
                visible: true
            });
        }
    }
    handleCancel = () => {
        this.setState({ visible: false, values: null, entryType: null,description:'',descriptionError:false });
        this.props.clearElement()
    }
    showModal = (event) => {
        this.setState({
            visible: true,
            event: event
        });
    }
    method = (value) => {
        this.setState({
            method: value
        })
    }

    choiceET = (value) => {
        let selectedEt = this.state.entryTypesList.find(el => el.type === value.entryType);
        this.setState({ entryType: selectedEt, parentId: value.parentId });
    }
    createTask = (value) => {
        const jsonValue = {
            name: value.name,
            description: value.description,
            properties: {}
        };
        for (let key in value) {
            if (value[key] !== undefined &&
                key !== "entryNew_statusCreatedEntry") {
                jsonValue.properties[key] = value[key];
            }
        };
        jsonValue.type = this.state.entryType.type;
        console.log(jsonValue);
        const modeling = this.props.modeler.get('modeling');
        const bpmnFactory = this.props.modeler.get("bpmnFactory");
        const elementFactory = this.props.modeler.get("elementFactory");
        const create = this.props.modeler.get("create");

        let ServiceTask = bpmnFactory.create('bpmn:ServiceTask');
        ServiceTask.definitionRef = 'modelerTemplate';
        ServiceTask.modelerTemplate = "com.aphinit.bpmn.delegate.EntryNew";//Change
        ServiceTask.delegateExpression = "${entryNew}";//Change

        let parentId = bpmnFactory.create('camunda:InputParameter', {
            name: "entryNew_ParentId",
            value: this.state.parentId
        });
        let json = bpmnFactory.create('camunda:InputParameter', {
            name: "entryNew_Json",
            value: JSON.stringify(jsonValue)
        });
        let status = bpmnFactory.create('camunda:InputParameter', {
            name: "entryNew_statusCreatedEntry",
            value: value.entryNew_statusCreatedEntry
        });

        let inputOutput = bpmnFactory.create('camunda:InputOutput', {
            inputParameters: [json, status, parentId],
            outputParameters: []
        });

        let entryTypeProperty = bpmnFactory.create('camunda:Property', {
            name: "entryType",
            value: this.state.entryType.type
        });
        let parentIdProperty = bpmnFactory.create('camunda:Property', {
            name: "parentId",
            value: this.state.parentId
        });

        const extensionElements = bpmnFactory.create("bpmn:ExtensionElements");
        extensionElements.set("values", [inputOutput, entryTypeProperty, parentIdProperty]);
        const serviceTask = elementFactory.createShape({
            type: "bpmn:ServiceTask",
            x: this.state.values ? this.props.activeElement.x + 50 : 0,
            y: this.state.values ? this.props.activeElement.y + 40 : 0,
            isExpanded: true,
            businessObject: ServiceTask
        });
        serviceTask.businessObject.set('name', value.taskName ? value.taskName : "New entry " + this.state.entryType.name);
        serviceTask.businessObject.set("extensionElements", extensionElements);
        if (this.state.values) {
            modeling.replaceShape(this.props.activeElement, serviceTask);
            this.props.clearElement();
        }
        else {
            create.start(this.state.event, [serviceTask], {
                hints: {
                    autoSelect: [serviceTask]
                }
            });
        }
        this.setState({
            visible: false, values: null, entryType: null, parentId: null,description:'',descriptionError:false
        });
    };
    handleRelationChanged(values) {
        this.setState({ relationData: values });
        let relValues = {
            type: "outgoing",
            relations: values
        };
        this.props.onRelationChange("outgoing", relValues);
    }
    handleDescriptionChange = (e) => {
        const value = e.target.value;
        if (value.length <= 2000) {
          this.setState({ description: value, descriptionError: false });
        } else {
          this.setState({ descriptionError: true });
        }
      };
    render() {
        const { entryType, entryTypesList,descriptionError,description } = this.state
        let main = null;
        if (!isUndefined(entryTypesList)) {

            let options = entryTypesList.filter(eT => eT.systemType === "object").map(el => ({ value: el.type, label: el.name, key: el.type }));

            main = <Form name="basic" layout="vertical" onFinish={this.choiceET} autoComplete="off">
                <Form.Item name="parentId" label="Parent Id" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="entryType" label="Entry Type" rules={[{ required: true }]}>
                    <Select showSearch style={{ width: "100%" }} options={options} />
                </Form.Item>
                <Button style={{ width: "100%" }} type="primary" htmlType="submit">OK</Button>
                <Button style={{ width: "100%" }} onClick={this.handleCancel}>Cancel</Button>
            </Form>
        }
        if (this.state.entryType) {
            main = <Form name="basic" layout="vertical" onFinish={this.createTask} initialValues={this.state.values} autoComplete="off">
                <Form.Item name="taskName" label="Task name" >
                    <Input />
                </Form.Item>
                <Form.Item name="entryNew_statusCreatedEntry" label="Status" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="name" label="Entry Name">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    validateStatus={descriptionError ? 'error' : ''}
                    help={descriptionError ? 'Description cannot exceed 2000 characters' : ''}
                    name="description"
                >
                    <Input.TextArea
                        onChange={this.handleDescriptionChange}
                        rows={6}
                    />
                </Form.Item>
                <AttributeForm properties={entryType.properties} />
                <Button style={{ width: "100%", marginTop: '10px' }} disabled={descriptionError} type="primary" htmlType="submit">OK</Button>
                <Button style={{ width: "100%" }} onClick={this.handleCancel}>Cancel</Button>
            </Form>
        }
        let modal = <Modal title="Entry new" destroyOnClose={true} width={this.state.entryType ? "70%" : "40%"} open={this.state.visible} footer={false} onCancel={this.handleCancel} >
            {main}
        </Modal>
        return <>{modal}<Tooltip placement="rightTop" title="Entry new">
            <span onClick={(event) => this.showModal(event)}><img src={entryNewImg} alt="entryNew" width="22px" height="22px" /></span>
        </Tooltip>
        </>
    }
}

export default EntryNew;