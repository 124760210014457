import React, { Component } from "react";
import { Form } from '@bpmn-io/form-js-viewer';
import "@bpmn-io/form-js/dist/assets/dragula.css";
import "@bpmn-io/form-js/dist/assets/form-js.css";
import "@bpmn-io/form-js/dist/assets/form-js-editor.css";
import { generateID } from "../../utils/generateID";




class MyFormViewer extends Component {
    FormViewer = null;
    constructor(props) {
        super(props);

        this.state = {
            schema: null,
            json: null,
            saveSchema: false,
            id:null
        };

    }
    componentDidMount() {
        if (this.props.data && !this.FormViewer) {
            this.setState({
                schema:this.props.data,
                id:"form"+generateID(),
            });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.data !== this.state.schema) {
            this.importForm(this.state.schema)
           
        }
     
    }
    importForm = async (schema) => {
        if(!this.FormViewer){
            this.FormViewer = new Form({
                container: document.querySelector('#' + this.state.id)
            });
        }
        try {
           await this.FormViewer.importSchema(schema);
       
        } catch (err) {
            console.log('importing form failed', err);
        }
    }
    render = () => {
            let main = null;
            if(this.state.schema){
                main = <div className="formEditor" style={{ width: "300px", height: "250px", overflow:"scroll", overflowX:'hidden'  }}  id={this.state.id} ></div>
            }
        return (
            <>
                {main}
            </>
        );
    };
}

export default MyFormViewer;
