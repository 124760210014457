import React, { Component } from "react";
import { Button, Modal, Card, Row, Col, Tabs, Table, Divider, Statistic, Result, Popconfirm, Tooltip } from 'antd';
import { DeleteOutlined, ContainerOutlined } from '@ant-design/icons';
import "@bpmn-io/form-js/dist/assets/dragula.css";
import "@bpmn-io/form-js/dist/assets/form-js.css";
import "@bpmn-io/form-js/dist/assets/form-js-editor.css";
import MyFormViewer from "./MyFormViewer";
import './style.css';
import { deleteFileInLocalStorege } from "../../utils/FileFromLocalStorege";
import MyDiagramViewer from "../diagramView/MyDiagramViewer";
import moment from "moment/moment";



class ModalSavedFile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            savedBpmn: [],
            savedForm: [],
            visibleSave: false,
            savedDmn: [],

        };

    }

    handleCancel = () => {
        this.setState({ visibleSave: false });
    }
    showModal = () => {
        let bpmnList = localStorage.getItem('savedBpmn') ? JSON.parse(localStorage.getItem('savedBpmn')) : [];
        let formList = localStorage.getItem('savedForm') ? JSON.parse(localStorage.getItem('savedForm')) : [];
        let dmnList = localStorage.getItem('savedDmn') ? JSON.parse(localStorage.getItem('savedDmn')) : [];
        this.setState({
            visibleSave: true,
            savedBpmn: bpmnList,
            savedForm: formList,
            savedDmn: dmnList,
        });
    }
    selectForm = (schema) => {
        this.props.createNew('form', schema.data, schema.name);
        this.setState({ visibleSave: false });
    }
    selectBpmn = (schema) => {
        this.props.createNew('bpmn', schema.data, schema.name);
        this.setState({ visibleSave: false });
    }
    selectDmn = (diagram) => {
        this.props.createNew('dmn', diagram.data, diagram.name);
        this.setState({ visibleSave: false });
    }
    deleteFile = (fileName, type) => {
        deleteFileInLocalStorege(type, fileName);
        let fileList = [];
        let inLocalStorege = localStorage.getItem(type)? JSON.parse(localStorage.getItem(type)):[];
        if (inLocalStorege) {
            fileList = inLocalStorege
        }
        switch (type) {
            case "savedBpmn":
                this.setState({ savedBpmn: fileList });
                break;
            case 'savedForm':
                this.setState({ savedForm: fileList });
                break;
            case 'savedDmn':
                this.setState({ savedDmn: fileList });
                break;

            default:
                break;
        }

    }
    colorStatistic = (length) => {
        let color = 'black'
        if (length <= 10) {
            color = "#a0d911"
        }
        if (length > 10 && length < 15) {
            color = "#faad14"
        }
        if (length > 15) {
            color = "#f5222d"
        }
        return color
    }

    render = () => {
        let cardForm = [];
        let cardBpmn = []
        let columns = [{
            dataSource: "name",
            key: "name",
            render: (text, record) => {
                return (
                    <a onClick={() => this.selectDmn(record)}>{text.name}</a>
                )
            },
        },
        {
            dataSource: "create",
            key: "create",
            render: (text, record) => {
                return (
                    <span>{moment(text.create).fromNow()}</span>
                )
            },
        },
        {
            dataSource: "name",
            key: "name",
            width: "25%",
            render: (text, record) => {
                return (
                    <Popconfirm
                        title="This is old version are you sure?"
                        okText='Yes'
                        onConfirm={() => this.deleteFile(text, 'savedDmn')}
                    >
                        <Button type="link" ><DeleteOutlined style={{ fontSize: '14px', color: 'red' }} /> </Button>
                    </Popconfirm>

                )
            },
        },
        ]
        if (this.state.savedForm.length > 0) {
            this.state.savedForm.forEach((el, index) => {
                cardForm.push(<Col key={el.name} span={6}>
                    <Card
                        className="formView"
                        extra={<Popconfirm
                            title="This is old version are you sure?"
                            okText='Yes'
                            onConfirm={() => this.deleteFile(el.name, 'savedForm')}
                        >
                            <Button type="link" ><DeleteOutlined style={{ fontSize: '14px', color: 'red' }} /> </Button>
                        </Popconfirm>}
                        key={el.name}
                        actions={[
                            <span>{moment(el.create).fromNow()}</span>,
                        ]}
                        title={el.name}
                        style={{ width: "100%", height: "100%", }}>
                        <a onClick={() => this.selectForm(el)}>
                            <MyFormViewer data={el.data} formId={'formViewer' + index} visible={this.state.visibleSave} />
                        </a>
                    </Card>

                </Col>)
            })
        }
        else {
            cardForm = <div style={{ justifyContent: 'center', display: 'flex', width: '100%' }}><Result
                status="404"
                title="You haven't saved anything yet"
                subTitle=""
            />
            </div>
        }


        if (this.state.savedBpmn.length > 0) {
            this.state.savedBpmn.forEach((el, index) => {
                cardBpmn.push(<Col key={el.name} span={6}>
                    <Card
                        className="formView"
                        extra={<Popconfirm
                            title="This is old version are you sure?"
                            okText='Yes'
                            onConfirm={() => this.deleteFile(el.name, 'savedBpmn')}
                        >
                            <Button type="link" ><DeleteOutlined style={{ fontSize: '14px', color: 'red' }} /> </Button>
                        </Popconfirm>}
                        key={el.name}
                        actions={[
                            <span>{moment(el.create).fromNow()}</span>,
                        ]}
                        title={el.name}
                        style={{ width: "100%", height: "100%", }}>
                        <a onClick={() => this.selectBpmn(el)}>
                            <MyDiagramViewer data={el.data} formId={'formViewer' + index} visible={this.state.visibleSave} />
                        </a>
                    </Card>

                </Col>)
            })
        }
        else {
            cardBpmn = <div style={{ justifyContent: 'center', display: 'flex', width: '100%' }}><Result
                status="404"
                title="You haven't saved anything yet"
                subTitle=""
            />
            </div>
        }
        let formStatistic = <Statistic
            value={this.state.savedForm.length}
            valueStyle={{
                color: this.colorStatistic(this.state.savedForm.length),
                fontSize: '16px',
            }}
            suffix="/ 20"
        />
        let dmnStatistic = <Statistic
            value={this.state.savedDmn.length}
            valueStyle={{
                color: this.colorStatistic(this.state.savedDmn.length),
                fontSize: '16px',
            }}
            suffix="/ 20"
        />
        let bpmnStatistic = <Statistic
            value={this.state.savedBpmn.length}
            valueStyle={{
                color: this.colorStatistic(this.state.savedBpmn.length),
                fontSize: '16px',
            }}
            suffix="/ 20"
        />

        let items = [
            {
                label: "BPMN",
                children: < Row gutter={[24, 24]}><Divider orientation="right" style={{ margin: '0' }}>{bpmnStatistic}</Divider>{cardBpmn}</Row >,
                key: 1
            },
            {
                label: "Form",
                children: < Row gutter={[24, 24]}><Divider orientation="right" style={{ margin: '0' }}>{formStatistic}</Divider>{cardForm}</Row >,
                key: 2
            },
            {
                label: "DMN",
                children: <>
                    <Divider orientation="right" style={{ margin: '0' }}>{dmnStatistic}</Divider>
                    <Table
                        showHeader={false}
                        pagination={false}
                        columns={columns}
                        dataSource={this.state.savedDmn}
                        rowKey="name"
                    /></>,
                key: 3
            }
        ]
        let modal =
            <Modal title="My saved files" width={1300} style={{ overflowY: "800px", maxHeigth: '800px' }} destroyOnClose={true} key={'ModalSaveFile'} open={this.state.visibleSave} onCancel={this.handleCancel} footer={false}>
                <div className="card-container">
                    <Tabs tabPosition="left" type="line" items={items} style={{ maxHeight: '800px', overflow: "scroll", overflowX: "hidden", paddingRight: "30px" }} />
                </div>
            </Modal>

        let name = this.props.type === 'link' ? <Tooltip placement="bottom" title="My saved files">
            <ContainerOutlined style={{ color: '#595959', fontSize: '18px' }} />
        </Tooltip> : <span><ContainerOutlined />  My saved files</span>;
        return (
            <>
                {modal}
                <Button type={this.props.type} size={this.props.type === 'link' ? 'default' : 'large'} style={this.props.type === 'primary' ? { backgroundColor: '#223366' } : { padding: '0' }} block onClick={this.showModal}>{name}</Button>
            </>
        );
    };
}

export default ModalSavedFile;
