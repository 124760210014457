import { connect } from 'react-redux';
import { fetchObjectList } from '../actions/objectList';
import { fetchUserCustomizations } from "../actions/userCustomizations";
import MainPresenter from '../components/Main';
import { isUndefined } from '../utils/JsObjectHelper';

const getSetup = (customizationsList) => {
    if (!isUndefined(customizationsList) && customizationsList.getState().isDone()) {
        let setList = customizationsList.getData().find(el => el.type === "modelerSetup");
		if(isUndefined(setList)){
			setList = null
		}
        return setList;
    } else {
        return null;
    }
};



const mapStateToProps = (state) => ({
    mySetup: getSetup(state.userCustomizations),
    notifications: state.notifications,
    entryTypeList: state.objectList
});

const mapDispatchToProps = dispatch => {
    return {
        onMount: () => {
            dispatch(fetchObjectList(true));
            dispatch(fetchUserCustomizations());
		},
    }
};


const Main = connect(mapStateToProps, mapDispatchToProps)(MainPresenter);

export default Main;

// App.propTypes = {
//     keycloak: PropTypes.any.isRequired
// };