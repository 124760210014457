export const  generateID =()=> {
    let digit
    let id =''
    let numberIndex
    let lettersSetArray = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
    let idArray = [];
    let digitsPositionArray = [];


    for (var i = 0; i < 7; i++) {
      digitsPositionArray.push(i);
      var upperCase = Math.round(Math.random() * 1);
      if (upperCase === 0) {
        idArray[i] = lettersSetArray[Math.floor(Math.random()*lettersSetArray.length)].toUpperCase();
      }
      else {
        idArray[i] = lettersSetArray[Math.floor(Math.random()*lettersSetArray.length)];
      }
    }
    for (i = 0; i < 4; i++) {
      digit = Math.round(Math.random() * 9);
      numberIndex = digitsPositionArray[Math.floor(Math.random()*digitsPositionArray.length)];

      idArray[numberIndex] =  digit;
      var j = digitsPositionArray.indexOf(numberIndex);
      if(i !== -1) {
        digitsPositionArray.splice(j, 1);
      }
    }
    id = idArray.join("");
    return id
  }