import React, { Component } from "react";
import { Button, Dropdown, Space, Tooltip, Upload } from 'antd';
import { ProfileOutlined, BoxPlotOutlined, ExportOutlined, InsertRowBelowOutlined, CloseOutlined, FolderOpenOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ModalSavedFile from "./savedFiles/ModalSavedFile";
import ModalDiagramView from "./diagramView/ModalDiagramView";
import DmnModeler from "./DmnModeler";
import BpmnMyModeler from "./BpmnMyModeler";
import { saveFileInLocalStorege } from "../utils/FileFromLocalStorege";
import FormEdit from "./FormEdit";
import HomePage from "./HomePage";
import { isNull } from "lodash";
import { isNumber } from "../utils/JsObjectHelper";
import NotificationComponent from "./bpmn-js-nyan/NotificationComponent";
import Setup from "../containers/Setup";
import MainContext from "../utils/Context";
import DmnExcel from "./DmnExcel/DmnExcel";
import ManualMain from "./manual/ManualMain";

const cloneDeep = require('lodash.clonedeep');

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeKey: 0,
			items: [],
			nameDiagram: null,
			savedForms: [],
			count: 0,
			delIndex: null,
			eventModal: false,
			eventKeyboard: true,
			showMessageCat: false
		};
		this.handleKeydown = this.handleKeydown.bind(this);
	}
	componentDidMount() {
		document.addEventListener('keydown', this.handleKeydown);
		this.props.onMount();
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeydown);
	}
	handleKeydown(event) {
		if (event.ctrlKey && event.key === 's') {
			event.preventDefault();
			if (!this.state.visible) {
				this.setState({ eventModal: 'save' });
			}
		}
		if (event.ctrlKey && event.key === 'd') {
			event.preventDefault();
			if (!this.state.visible) {
				this.setState({ eventModal: 'download' });
			}
		}
	};
	showMessageCat = (state) => {
		this.setState({ showMessageCat: state })
	}
	offModal = () => {
		this.setState({ eventModal: false });
	}
	offEventKeyboard = () => {
		this.setState({ eventKeyboard: false })
	}
	createNew = (type, data, name) => {
		let clone = []
		let newActiveKey = 0
		if (this.state.items.length > 0) {
			clone = cloneDeep(this.state.items)
			newActiveKey = this.state.count + 1;
		}
		switch (type) {
			case 'bpmn':
				let nameDiagram = name ? name : `diagram_${newActiveKey}.bpmn`;
				let starBpmn = data ? null : <span style={{ color: "red" }}>*</span>;
				clone.push(
					{
						type: 'bpmn',
						label: (<span key={newActiveKey}>
							<BoxPlotOutlined style={{ color: 'orange', marginRight: '10px' }} />
							{starBpmn}{nameDiagram}
						</span>),
						name: nameDiagram,
						data: data,
						canvasId: "canvas" + newActiveKey,
						propertiesId: "properties" + newActiveKey,
						key: newActiveKey,
					}
				);
				break;
			case 'dmn':
				let nameDmn = name ? name : `diagram_${newActiveKey}.dmn`;
				let starDmn = data ? null : <span style={{ color: "red" }}>*</span>;
				clone.push(
					{
						type: 'dmn',
						label: (<span key={newActiveKey}>
							<InsertRowBelowOutlined style={{ color: '#1890ff', marginRight: '10px' }} />
							{starDmn}{nameDmn}
						</span>),
						name: nameDmn,
						data: data,
						containerId: "container" + newActiveKey,
						propertiesId: "properties" + newActiveKey,
						key: newActiveKey,
					}
				);
				break;
			case 'form':
				let nameForm = name ? name : `form_${newActiveKey}.form`;
				let star = data ? null : <span style={{ color: "red" }}>*</span>;
				clone.push(
					{
						type: 'form',
						label: (<span key={newActiveKey} >
							<ProfileOutlined style={{ color: 'green', marginRight: '10px' }} />
							{star}{nameForm}
						</span>),
						name: nameForm,
						data: data,
						formId: "form" + newActiveKey,
						key: newActiveKey,
					}
				);
				break;
			default:
				break;
		}
		this.setState({
			items: clone,
			activeKey: clone.length - 1,
			count: newActiveKey
		})
	}
	openDiagram = (data) => {
		let res = data.target.result
		this.createNew('bpmn', res, this.state.nameDiagram);
	}
	openDmn = (data) => {
		let res = data.target.result
		this.createNew('dmn', res, this.state.nameDiagram);
	}
	openForm = (data) => {
		let res = JSON.parse(data.target.result);
		this.createNew('form', res, this.state.nameDiagram);
	}
	beforeUpload = (file) => {
		const isFile = file.name.includes(".bpmn") || file.name.includes(".form") || file.name.includes(".dmn");

		return !isFile;
	};
	handleOpenFile = (info) => {
		const reader = new FileReader();
		if (info.file.name.includes("bpmn")) {
			reader.onloadend = this.openDiagram
			reader.readAsText(info.fileList[0].originFileObj);
		}
		else if (info.file.name.includes('.form')) {
			reader.onloadend = this.openForm
			reader.readAsText(info.fileList[0].originFileObj);
		}
		else if (info.file.name.includes('.dmn')) {
			reader.onloadend = this.openDmn
			reader.readAsText(info.fileList[0].originFileObj);
		}
		this.setState({ nameDiagram: info.file.name });
	};
	setSelectedFile = (file) => {
		if ((file.definition && file.definition.resource) || (file.file.name.includes('.bpmn'))) {
			if (file.definition) {
				this.createNew('bpmn', file.bpmn20Xml, file.definition.resource);
			}
			else {
				this.createNew('bpmn', file.data, file.file.name);
			}
		}
		else if (file.file.name.includes('.form')) {
			this.createNew('form', file.data, file.file.name);
		}
		else if (file.file.name.includes('.dmn')) {
			this.createNew('dmn', file.data, file.file.name);
		}
	}
	saveFileItems = (type, name, key, data, edit) => {
		let result = saveFileInLocalStorege(type, this.state.items, name, key, data, edit)
		this.setState({
			items: result.clone,
			savedForms: result.files
		});
	}
	setTabIndex = (index) => {
		this.setState({ activeKey: index });
	}
	checkDelete = (index) => {
		let star = this.state.items[index].label.props.children[2];
		if (!isNull(star) && star !== undefined) {
			this.setState({
				activeKey: index,
				delIndex: index
			});
		}
		else {
			this.removeTab(index);
		}
	}
	removeTab = (index) => {
		if (index === 'cancel') {
			this.setState({ delIndex: null });
		}
		if (isNumber(index)) {
			let clone = cloneDeep(this.state.items);
			clone.splice(index, 1);
			if (index < this.state.activeKey) {
				this.setState({ activeKey: this.state.activeKey - 1 });
			}
			if (clone.length > 0 && index === this.state.activeKey) {
				this.setState({ activeKey: 0 });
			}
			if (clone.length === index && index === this.state.activeKey) {
				this.setState({ activeKey: clone.length - 1 });
			}
			this.setState({
				items: clone,
				delIndex: null
			});
		}
	}
	render() {
		const items = [
			{
				key: '1',
				label: (<Button type="link" onClick={() => this.createNew('bpmn')} ><BoxPlotOutlined style={{ color: 'orange' }} /> BPMN</Button>),
			},
			{
				key: '2',
				label: (<Button type="link" onClick={() => this.createNew('dmn')}><InsertRowBelowOutlined style={{ color: '#1890ff' }} /> DMN</Button>),
			},
			{
				key: '3',
				label: (<Button type="link" onClick={() => this.createNew('form')}><ProfileOutlined style={{ color: 'green' }} /> Form</Button>),
			},
		];
		let menu = (<div style={{ backgroundColor: 'hsl(220deg 12% 95%)', display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
			<Space size='large' style={{ backgroundColor: 'hsl(220deg 12% 95%)', marginLeft: '20px' }}>
				<Dropdown
					menu={{
						items,
					}}><PlusCircleOutlined style={{ color: '#595959', fontSize: '20px', padding: '0' }} /></Dropdown>
				<Tooltip placement="bottom" title="Open">
					<Upload
						beforeUpload={this.beforeUpload}
						maxCount={1}
						showUploadList={false}
						onChange={this.handleOpenFile}
					>
						<Button type="link" style={{ color: 'black', padding: '0' }} ><FolderOpenOutlined style={{ color: '#595959', fontSize: '20px' }} /></Button>
					</Upload>

				</Tooltip>
				<ModalDiagramView openDeployedProcess={this.setSelectedFile} type={"link"} />
				<ModalSavedFile createNew={this.createNew} type={"link"} />
				<DmnExcel createNew={this.createNew} />
			</Space>
			<Space size='small' style={{ backgroundColor: 'hsl(220deg 12% 95%)', marginLeft: '20px' }}>
				<ManualMain/>
				<Setup mySetup={this.props.mySetup}/>
				<Button type="link" onClick={this.props.onLogout} style={{ color: 'black', marginTop: '10px' }} ><ExportOutlined style={{ fontSize: '18px' }} /></Button>
			</Space>
		</div>);
		let main = <HomePage createNew={this.createNew} setSelectedFile={this.setSelectedFile} handleOpenFile={this.handleOpenFile} beforeUpload={this.beforeUpload} />
		let tab = [];
		let tabPanel = [];
		let safari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1 ? "sfariTab closeBtnForTab" : 'closeBtnForTab';
		if (this.state.items.length > 0) {
			this.state.items.forEach((modeler, index) => {
				tab.push(<><Tab key={modeler.key + 'tab'} ><Tooltip key={modeler.key + 'tooltip'} placement="bottom" color='silver' title={modeler.label}>{modeler.label}</Tooltip></Tab>
					<CloseOutlined key={modeler.key + 'close'} onClick={() => this.checkDelete(index)} className={safari} /></>)
				switch (modeler.type) {
					case 'form':
						tabPanel.push(<TabPanel key={modeler.key} forceRender={true}>
							<FormEdit
							itemKey={modeler.key}
							index={index}
							name={modeler.name}
							data={modeler.data}
							formId={modeler.formId}/></TabPanel>)
						break;
					case 'dmn':
						tabPanel.push(<TabPanel key={modeler.key} forceRender={true}>
							<DmnModeler
							itemKey={modeler.key}
							index={index}
							name={modeler.name}
							data={modeler.data}
							containerId={modeler.containerId}
							propertiesId={modeler.propertiesId} /></TabPanel>)
						break;
					case 'bpmn':
						tabPanel.push(<TabPanel key={modeler.key} forceRender={true}>
							<BpmnMyModeler
							itemKey={modeler.key}
							index={index}
							name={modeler.name}
							data={modeler.data}
							eventKeyboard={this.state.eventKeyboard}
							canvasId={modeler.canvasId}
							propertiesId={modeler.propertiesId}
							offEventKeyboard={this.offEventKeyboard} />
							</TabPanel>)
						break;
					default:
						break;
				}
			})
			main = <div>
				<MainContext.Provider value={{
					offModal: this.offModal,
					delIndex: this.state.delIndex,
					removeTab: this.removeTab,
					activeIndex: this.state.activeKey,
					eventModal: this.state.eventModal,
					items: this.state.items,
					saveItems: this.saveFileItems,
					mySetup: this.props.mySetup,
					visibleMessageCat: this.state.showMessageCat,
					showMessageCat: this.showMessageCat,
					entryTypeList:this.props.entryTypeList
				}}>
					<NotificationComponent items={this.state.items} mySetup={this.props.mySetup}  />
					{menu}
					<Tabs selectedIndex={this.state.activeKey} onSelect={(index) => this.setTabIndex(index)}  >

						<TabList>
							{tab}
						</TabList>

						{tabPanel}
					</Tabs>
				</MainContext.Provider>
			</div>
		}
		return main
	}
}
export default Main;