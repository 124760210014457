import React, { Component } from "react";
import DmnJS from "dmn-js/lib/Modeler";
import { Button, Drawer, Modal, Input, Space, Tooltip } from 'antd';
import { ArrowDownOutlined, BoxPlotOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import camundaModdleDescriptor from "camunda-dmn-moddle/resources/camunda";
import { DmnPropertiesPanelModule } from "dmn-js-properties-panel/dist";
import { DmnPropertiesProviderModule } from "dmn-js-properties-panel/dist";
import "dmn-js-properties-panel/dist/assets/dmn-js-properties-panel.css";
import "dmn-js/dist/assets/diagram-js.css";
import "dmn-js/dist/assets/dmn-font/css/dmn-embedded.css";
import "dmn-js/dist/assets/dmn-js-decision-table-controls.css";
import "dmn-js/dist/assets/dmn-js-decision-table.css";
import "dmn-js/dist/assets/dmn-js-drd.css";
import "dmn-js/dist/assets/dmn-js-literal-expression.css";
import "dmn-js/dist/assets/dmn-js-shared.css";
import { generateID } from "../utils/generateID";
import SaveFiles from "./SaveFiles";
import MainPanel from "../containers/MainPanel";
import MainContext from "../utils/Context";

class DmnModeler extends Component {
    static contextType = MainContext;
    dmnEditor = null;
    constructor(props) {
        super(props);
        this.state = {
            saveDiagram: null,
            diagram: [],
            xml: null,
            visible: false,
            nameFile: null,
            errors: null,
            visibleDel: false
        };

    }
    componentDidMount() {
        let initialDiagram = null;
        if (this.props.data && this.state.diagram.length === 0) {
            initialDiagram = this.props.data
            this.setState({ saveSchema: true })
        }
        else if (this.state.diagram.length === 0) {
            let id = generateID();
            initialDiagram = `<?xml version="1.0" encoding="UTF-8"?>
			<definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" xmlns:dmndi="https://www.omg.org/spec/DMN/20191111/DMNDI/" xmlns:modeler="http://camunda.org/schema/modeler/1.0" id="Definitions_${id}" name="DRD" namespace="http://camunda.org/schema/1.0/dmn" exporter="Camunda Modeler" exporterVersion="5.0.0" modeler:executionPlatform="Camunda Platform" modeler:executionPlatformVersion="7.17.0">
			  <dmndi:DMNDI>
				<dmndi:DMNDiagram />
			  </dmndi:DMNDI>
			</definitions>`
        }
        this.dmnEditor = new DmnJS({
            container: document.getElementById(this.props.containerId),
            width: "100%",
            height: "100%",
            position: "absolute",
            decisionTable: {
                keyboard: {
                    bindTo: document
                }
            },
            moddleExtensions: {
                camunda: camundaModdleDescriptor
            },
            drd: {
                additionalModules: [
                    DmnPropertiesPanelModule,
                    DmnPropertiesProviderModule,

                ]
            }
        });

        this.dmnEditor.on("views.changed", ({ activeView }) => {
            const propertiesPanel = this.dmnEditor.getActiveViewer().get("propertiesPanel", false);

            if (propertiesPanel) {
                propertiesPanel.detach();

                if (activeView.type === "drd") {
                    propertiesPanel.attachTo(
                        document.getElementById(this.props.propertiesId)
                    );
                }
            }
        });
        this.setState({ nameFile: this.props.name })
        this.openDiagram(initialDiagram)
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.context.delIndex === this.props.index && this.state.visibleDel === false) {
            this.setState({ visibleDel: true })
        }
        if (this.context.activeIndex === this.props.index && this.context.eventModal === 'download' && !this.state.visible) {
            this.showModal();
        }
    }
    openDiagram = async (dmn) => {
        this.dmnEditor.importXML(dmn)
            .then(({ warnings }) => {
                if (warnings.length) {
                    console.log("Warnings", warnings);
                }
            })
            .catch((err) => {
                this.setState({ errors: err })
                console.log("error", err);
            });


    }
    exportDiagram = async () => {
        try {
            const { xml } = await this.dmnEditor.saveXML({ format: true });
            let name = this.state.nameFile.includes('.dmn');
            name = name ? this.state.nameFile : this.state.nameFile + ".dmn";
            this.setEncoded(xml, name)
        } catch (err) {
            console.error('could not save DMN 1.3 diagram', err);
        }
    };
    setEncoded = (data, name) => {
        var a = document.createElement("a");
        a.href = "data:application/dmn-xml;charset=UTF-8," + encodeURIComponent(data);
        a.download = name;
        a.click();
    };

    showXml = async () => {
        if (this.state.xml) {
            this.setState({ xml: null })
        }
        else {
            try {
                const result = await this.dmnEditor.saveXML({ format: true });
                this.setState({ xml: result.xml })
            }
            catch (err) {
                console.log(err);
            }
        }
    };
    onClose = () => {
        this.setState({ xml: null })
    };
    showModal = (e) => {
        this.setState({ visible: true })
    }
    handleCancel = () => {
        this.setState({ visible: false })
        if (this.context.eventModal === 'download') {
			this.context.offModal()
		}
    }
    nameFile = (name) => {
        this.setState({ nameFile: name.target.value })
    }
    save = async (name) => {
        try {
            const result = await this.dmnEditor.saveXML({ format: true });
            let decisionID = this.dmnEditor._views.filter((el) => el.type === "literalExpression" || el.type === "decisionTable");
            let data = { id: decisionID, data: result.xml }
            this.context.saveItems("savedDmn", name, this.props.itemKey, data)
            if (this.state.visibleDel) {
                this.context.removeTab(this.props.index)
            }

            this.setState({
                nameFile: name,
                saveDiagram: true,
                visibleDel: false,
            })

        }
        catch (err) {
            console.log(err);
        }
    }
    handleCancelDel = (index) => {
        this.context.removeTab(index)
        this.setState({ visibleDel: false })
    }
    render() {
        const sharedProps = {
            style: {
                width: '100%',
            },
            defaultValue: this.state.nameFile,
        };
        let modalDelete = <Modal title='Close file?' destroyOnClose={true} open={this.state.visibleDel}
            footer={[
                <Button key="cancel" onClick={() => this.handleCancelDel('cancel')}>
                    Cancel
                </Button>,
                <SaveFiles name={this.state.nameFile} mode={'del'} save={this.save} type={"savedBpmn"} format={'.bpmn'} />,
                <Button
                    key="dontSave"
                    type="primary"
                    onClick={() => this.handleCancelDel(this.props.index)}
                >
                    Don't Save
                </Button>,
            ]} onCancel={() => this.handleCancelDel('cancel')}>
            <ExclamationCircleFilled style={{ fontSize: '24px', color: 'blue', marginRight: '10px' }} /><span style={{ fontSize: '16px' }}>Save changes to "{this.state.nameFile}" before closing? </span>
        </Modal>;

        let modal = (
            <Modal title="Download file" destroyOnClose={true} open={this.state.visible} onCancel={this.handleCancel} footer={false}>
                <div>Name file</div>
                <Input onChange={this.nameFile} {...sharedProps}></Input>
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                    <Button type="primary" disabled={!this.state.nameFile} onClick={this.exportDiagram} style={{ marginRight: '5px' }}><BoxPlotOutlined style={{ fontSize: '18px' }} />Download</Button>
                </div>
            </Modal>);

        let menu = (<Space size="middle" style={{ display: 'flex', height: '47px', backgroundColor: 'hsl(220deg 12% 95%)' }}>
            <Tooltip placement="top" title="Show xml">
                <Button type="link" onClick={this.showXml} style={{ color: '#595959' }}>{this.state.xml ? "Diagram" : "XML"}</Button>
            </Tooltip>
            <Tooltip placement="top" title="Download">
                <Button type="link" onClick={() => this.showModal('download')} style={{ color: '#595959' }}><ArrowDownOutlined style={{ color: '#595959', fontSize: '18px' }} /></Button>
            </Tooltip>
            <SaveFiles name={this.state.nameFile} save={this.save} type={"savedDmn"} format={'.dmn'}  index={this.props.index}   />
        </Space>);
        let drawer = (<Drawer
            title="View XML"
            placement="left"
            width={"100%"}
            onClose={this.onClose}
            open={this.state.xml}
            size='small'
            getContainer={false}
            style={{
                position: 'absolute',
                overflowY: 'hidden'
            }}
        >
            <pre className="codePre"><code className="language-xml" >{this.state.xml}</code></pre>
        </Drawer>);
        let main = <>
            <div className="site-drawer-render-in-current-wrapper">
                {drawer}
                <div className="contentDmn" style={{ backgroundColor: 'white' }} >
                    <div className="modelerDmn" >
                        <div id={this.props.containerId} className='DmnModeler'>
                        </div>
                    </div>
                    <MainPanel
                        index={this.props.index}
                        activeButton={this.state.activeButton}
                        modeler={this.modeler}/>
                    <div className="properties-panel" id={this.props.propertiesId}></div>
                </div>
            </div>
        </>
        return (<>{modalDelete}{modal}{main}{menu}</>)
    }
}

export default DmnModeler